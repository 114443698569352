import React, { Component } from "react";
import { FormInput } from "shards-react";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
/**
 * @augments {Component<Props, State>}
 */
class DisplayBoardsSettingsSplit extends Component {
  render() {
    return (
      <tr>
        <td align="center">
          <Checkbox
            checked={
              this.props.splitcheckbox === undefined
                ? false
                : this.props.splitcheckbox
            }
            onChange={(event) => {
              this.props.handleSplitChange(
                event.target.checked,
                this.props.splitKey,
                "checkbox"
              );
            }}
            color="primary"
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
            style={{ width: "2%", marginRight: "30%",fontFamily:'Poppins' }}
          />
        </td>
        <td style={{ width: "20%",fontFamily:'Poppins' }}>
          <label for="700" style={{ fontWeight: "normal",fontFamily:'Poppins' }}>
            {this.props.splitName}
          </label>
        </td>
        <td>
          <FormInput
            disabled={!this.props.splitcheckbox}
            type="number"
            style={{
              width: "63%",
              fontWeight: "bold",
              alignItems: "center",
              fontFamily:'Poppins',
              paddingRight: "0%",
              textAlign: "center",
            }}
            className={`textbox ${`position${""}`}`}
            name="Position"
            size="1"
            value={this.props.splitcheckbox ? this.props.position : 0}
            onChange={(event) => {
              this.props.handleSplitChange(
                event.target.value,
                this.props.splitKey,
                "position"
              );
            }}
          />
        </td>
        <td align="left">
          <table border="0" cellspacing="0" cellpadding="2">
            <tr>
              <td align="right">
                <b>
                  <FormInput
                    type="number"
                    disabled={!this.props.splitcheckbox}
                    className={`callswaitingwarning${""}`}
                    style={{
                      backgroundColor: "#FFFD82",
                      fontStyle: "bold",
                      fontFamily:'Poppins',
                      color: "#000",
                      width: "65%",
                      fontWeight: "bold",
                      paddingRight: "0%",
                      textAlign: "center",
                    }}
                    value={
                      this.props.splitcheckbox
                        ? this.props.callsWaitingWarning
                        : ""
                    }
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "callwarning"
                      );
                    }}
                  />
                </b>
              </td>
              <td align="left">
                <b>
                  <FormInput
                    type="number"
                    disabled={!this.props.splitcheckbox}
                    className={`callswaitingalert${""}`}
                    style={{
                      backgroundColor: "#ea1d36",
                      fontStyle: "bold",
                      color: "#000",
                      width: "65%",
                      fontWeight: "bold",
                      paddingRight: "0%",
                      textAlign: "center",
                      fontFamily:'Poppins'
                    }}
                    value={
                      this.props.splitcheckbox
                        ? this.props.callsWaitingAlert
                        : ""
                    }
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "callalert"
                      );
                    }}
                  />
                </b>
              </td>
            </tr>
          </table>
        </td>
        <td align="left">
          <table border="0" cellspacing="0" cellpadding="2">
            <tr>
              <td align="right">
                <b>
                  <FormInput
                    type="number"
                    disabled={!this.props.splitcheckbox}
                    className={`oldestcallwaitingwarning${""}`}
                    style={{
                      backgroundColor: "#FFFD82",
                      fontStyle: "bold",
                      color: "#000",
                      width: "65%",
                      fontFamily:'Poppins',
                      fontWeight: "bold",
                      paddingRight: "0%",
                      textAlign: "center",
                    }}
                    value={
                      this.props.splitcheckbox
                        ? this.props.oldestcallsWaitingWarning
                        : ""
                    }
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "oldestcallwarning"
                      );
                    }}
                  />
                </b>
              </td>
              <td align="left">
                <b>
                  <FormInput
                    type="number"
                    disabled={!this.props.splitcheckbox}
                    className={`oldestcallwaitingalert${""}`}
                    style={{
                      backgroundColor: "#ea1d36",
                      fontStyle: "bold",
                      fontFamily:'Poppins',
                      color: "#000",
                      width: "65%",
                      fontWeight: "bold",
                      paddingRight: "0%",
                      textAlign: "center",
                    }}
                    value={
                      this.props.splitcheckbox
                        ? this.props.oldestcallsWaitingAlert
                        : ""
                    }
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "oldestcallalert"
                      );
                    }}
                  />
                </b>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    );
  }
}
DisplayBoardsSettingsSplit.propTypes = {
  splitcheckbox: PropTypes.bool.isRequired,
  handleSplitChange: PropTypes.func,
  splitName: PropTypes.string.isRequired,
  splitKey: PropTypes.number.isRequired,
  position: PropTypes.number,
  callsWaitingWarning:PropTypes.number,
  callsWaitingAlert: PropTypes.number,
  oldestcallsWaitingWarning: PropTypes.number,
  oldestcallsWaitingAlert: PropTypes.number,

};
export default DisplayBoardsSettingsSplit;
