import React, { Component } from 'react';

class DisplayBoardAgentTable extends Component {


  timeConvertion(timeinseconds) {
    let min = parseInt(timeinseconds / 60)
    let sec = timeinseconds % 60
    let convertedTime = ''
    if (sec <= 9) {
      convertedTime = "" + min + ":" + "0" + sec
    }
    else {
      convertedTime = "" + min + ":" + sec
    }
    return convertedTime
  }
  timeConvertionToSeconds(time="00:00:00"){
    if(time!=null)
    {
      let hour=parseInt(time.substring(1,3))
      let min=parseInt(time.substring(3,5))
      let sec=parseInt(time.substring(6,8))
      return sec+min*60+hour*60*60
      
    }
    return 0
    
  }
  colorLogic(workmode, BordSettingsAcdWarningTime, BoardSettingsAcdAlertTime, agentAcdTime, duration,AuxTime,Direction,auxreasons ) {
    let agentRowClass = "agents-table-data-cell"
    if (workmode === "LOGGED OUT" ) {
      agentRowClass = "agents-table-data-cell-alert";
    }
    else {
      console.log("acdtime"+this.props.justACDTime)
      if (BordSettingsAcdWarningTime !== "0" && parseInt(this.timeConvertionToSeconds(agentAcdTime)) >= parseInt(BordSettingsAcdWarningTime)) {
        agentRowClass = "agents-table-data-cell-warning";
      }
      if (BoardSettingsAcdAlertTime !== "0" && parseInt(this.timeConvertionToSeconds(agentAcdTime)) >= parseInt(BoardSettingsAcdAlertTime)) {
        agentRowClass = "agents-table-data-cell-alert";
      }
    }
    
    this.props.auxreasons.map(({key,warningtime,alerttime})=>{
      console.log(this.props.auxReasonKey+ " "+key)

        if(parseInt(key)==parseInt(this.props.auxReasonKey) && parseInt(warningtime)==0)
        {
          agentRowClass = "agents-table-data-cell-warning";
        }
        if(parseInt(key)==parseInt(this.props.auxReasonKey) && parseInt(this.timeConvertionToSeconds(duration))>=warningtime)
        {
          agentRowClass = "agents-table-data-cell-warning";
        }
        if(parseInt(key)==parseInt(this.props.auxReasonKey) && parseInt(this.timeConvertionToSeconds(duration))>=alerttime)
        {
          agentRowClass = "agents-table-data-cell-alert";
        }
      
    })

    return agentRowClass
  }
  render() {
    const workMode = this.props.workMode
    const className = this.colorLogic(workMode, this.props.boardSettingsAcdWarningTime, this.props.boardSettingsAcdAlertTime, this.props.acdTime,this.props.duration,this.props.AuxTime,this.props.Direction,this.props.auxreasons )
    return (
      <>
        
        <tr>
          <td className={className} bgcolor="#ffffff" align='left'>{this.props.agentName}</td>
          <td className={className} bgcolor="#ffffff" align='left'>{workMode === "LOGGED OUT" ? "" : this.props.splitKey!="0"?this.props.splitName + " " + "(" + this.props.splitKey + ")":""}</td>
          <td className={className} bgcolor="#ffffff" align="center">{workMode === "LOGGED OUT" ? "" : this.props.acd}</td>
          <td className={className} bgcolor="#ffffff" align="center">{workMode === "LOGGED OUT" ? "" : this.props.acdTime!=null?this.props.acdTime:this.props.acdTime}</td>
          <td className={className} bgcolor="#ffffff" align="center">{workMode === "LOGGED OUT" ? "" : this.props.workMode+this.props.Direction}</td>
          <td className={className} bgcolor="#ffffff" align="center">{workMode === "LOGGED OUT" ? "" : this.props.auxReason=="0"?"":this.props.auxReason}</td>
          {this.props.boardId==8?<td className={className} bgcolor="#ffffff" align="center">{workMode === "LOGGED OUT" ? "" : this.props.auxInstance=="null"?"0":this.props.auxInstance}</td>:""}
          {this.props.boardId==8?<td className={className} bgcolor="#ffffff" align="center">{workMode === "LOGGED OUT" ? "" : this.props.AuxTimeTotal=="null"?"0":this.props.AuxTimeTotal}</td>:""}
          <td className={className} bgcolor="#ffffff" align="center">{workMode === "LOGGED OUT" ? "" : this.props.duration}</td>
        </tr>
        
      </>
    );
  }
}

export default DisplayBoardAgentTable;
