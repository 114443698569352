import React, { Component } from 'react';
import {
  Row,
  Col,
  FormInput,
} from "shards-react";
class DisplayBoardSettingsBoardName extends Component {
  render() {
    return (
      <Row>
        <Col lg='2' md='2' sm='2' xs='3' className="form-group d-flex align-items-center">
          <label htmlFor="feEmailAddress" style={{ paddingTop: '3%' ,fontFamily:'Poppins'}}>{this.props.displayName}</label>
        </Col>
        <Col lg={Boolean(this.props.expandName)?'10':'4'} md='4' sm='4' xs='3' className="form-group pl-0" >
          <FormInput
            id="feEmailAddress"
            type="text"
            value={this.props.boardName || this.props.podName}
            disabled={this.props.valid != undefined ? this.props.valid.type != 'post' ? true : false : false}
            maxLength="60"
            onChange={(event) =>
              this.props.setState(event.target.value)}
            style={{ fontStyle: 'bold', fontWeight: 'bold' ,fontFamily:'Poppins'}}
            placeholder={this.props.placeholder}
          />
        </Col>
        {
          this.props.extraoptions!=null? 
          <Col lg="4" className="form-group pl-0">
            {this.props.extraoptions}
          </Col>
          :null
        }
      </Row>
    );
  }
}

export default DisplayBoardSettingsBoardName;
