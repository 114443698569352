import axios from "axios";
import config from "../config";
class CallApis {
  //APIs are called here
  async createDimSplit(AccessToken) {
    let url = config.APP_BASE_API_URL + "DimSplit";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async createDimClinic(AccessToken) {
    let url = config.APP_BASE_API_URL + "DimClinic";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        let arr = [];
        for (let i = 0; i < response.data.length; i++) {
          arr.push({
            value: response.data[i].clinicKey,
            label: response.data[i].clinicName,
          });
        }
        return [response.data, arr];
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async getClinicDictionary(AccessToken) {
    let url = config.APP_BASE_API_URL + "DimClinic";
    let clinicDict = {};
    let { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
    });
    for (let object of data) {
      clinicDict[object.clinicKey] = object.clinicName;
    }
    return clinicDict;
  }
  async createRealtimeBoardSettingsIndivisual(Accesstoken, boardId) {
    let url = config.APP_BASE_API_URL + "RealTimeBoardSettings/" + boardId;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async createRealtimeBoardSettings(Accesstoken) {
    let url = config.APP_BASE_API_URL + "RealTimeBoardSettings";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
        return Promise.reject(error);
      });
  }

  async createNewRealtimeBoardSettingsIndividual(AccessToken, boardId) {
    let url = config.APP_POD_URL + "/podcontainers/" + boardId;

    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  async createNewRealtimeBoardSettings(Accesstoken) {
    let url = config.APP_POD_URL + "/podcontainers";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
        return Promise.reject(error);
      });
  }
  async createDimAuxReason(Accesstoken) {
    let url = config.APP_BASE_API_URL + "DimAuxReason";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async delete(AccessToken, boardId) {
    let url = config.APP_BASE_API_URL + "RealTimeBoardSettings/" + boardId;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        //window.location.reload(false);
        return true;
      })
      .catch((err) => {
        console.log(err);
        alert("Failed to Delete");
        return Promise.reject(err);
      });
  }
  async getRealTimeSplits(AccessToken) {
    let url = config.APP_BASE_API_URL + "RealTimeSplit";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((Error) => {
        return Promise.reject(Error);
      });
  }
  async getRealTimeAgents(AccessToken) {
    let url = config.APP_BASE_API_URL + "RealTimeAgent";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((Error) => {
        return Promise.reject(Error);
      });
  }
  async postSplits(AccessToken, postObj) {
    let url = config.APP_BASE_API_URL + "RealTimeBoardSettings";
    return axios
      .post(url, postObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        return true;
      })
      .catch((err) => {
        console.log("error", err.response);
        return Promise.reject(err);
      });
  }
  async postNewRealTimeBoardSettings(AccessToken, reqBody) {
    let url = config.APP_POD_URL + "/podcontainers";
    return axios
      .post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => true)
      .catch((err) => Promise.reject(err));
  }
  async putSplits(AccessToken, putobj, boardId) {
    let url = config.APP_BASE_API_URL + "RealTimeBoardSettings/" + boardId;
    return axios
      .put(url, putobj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        return true;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  async putNewRealTimeBoard(AccessToken, putobj, boardId) {
    let url = config.APP_POD_URL + "/podcontainers/" + boardId;
    return axios
      .put(url, putobj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        return true;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  async getDisplayBoard(Accesstoken, boardId) {
    let url = config.APP_BASE_API_URL + "RealTimeDisplayPod/" + boardId;
    let response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Accesstoken}`,
      },
    });
    console.log(response.data)
    return response.data;
    // return axios
    //   .get(url, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${Accesstoken}`,
    //     },
    //   })
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .catch((error) => {
    //     return Promise.reject(error);
    //   });
  }
  async getNewDisplayBoard(Accesstoken, boardId) {
    let url = config.APP_POD_URL + "/realtimepodcontainer/" + boardId;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getStaticDisplayBoard(Accesstoken, boardId) {
    let url = config.APP_BASE_API_URL + "DisplayBoard/" + boardId;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async getStaticNewDisplayBoard(Accesstoken, boardId) {
    let url = config.APP_POD_URL + "/realtimepodcontainerstatic/" + boardId;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async postNewPod(AccessToken, postObj) {
    let url = config.APP_POD_URL;
    return axios
      .post(url, postObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((res) => {
        return true;
      })
      .catch((err) => {
        console.log("error", err.response);
        return Promise.reject(err);
      });
  }

  async getAllPods(AccessToken) {
    let url = config.APP_POD_URL;
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((res) => {
        let form_data = res.data.map((el) => ({
          label: el.podName,
          value: el.podKey,
        }));
        return [res.data, form_data];
      })
      .catch((err) => Promise.reject(err));
  }

  async getVersionAndDatabase(Accesstoken) {
    let url = config.APP_BASE_API_URL + "VersionAndDatabase/";
    return axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Accesstoken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async deleteNewBoard(AccessToken, boardId) {
    let url = config.APP_POD_URL + "/podcontainers/" + boardId;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        //window.location.reload(false);
        return true;
      })
      .catch((err) => {
        console.log(err.response);
        alert("Failed to Delete");
        return Promise.reject(err);
      });
  }
  async updatePod(AccessToken, podId, pod) {
    let url = config.APP_POD_URL + "/" + podId;
    return axios
      .put(url, pod, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        console.log(err.response);
        return Promise.reject(err);
      });
  }
  async deletePod(AccessToken, podId) {
    console.log("request to delete podkey", podId);
    let url = config.APP_POD_URL + "/" + podId;
    return axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        return true;
      })
      .catch((err) => {
        console.log(err.response);
        return Promise.reject(err);
      });
  }
}

export default CallApis;
