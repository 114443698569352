class getSplitKeys {


    getSplitKey(splitarr){
        let SplitKeys=[]
        let position=[]
        for(let i=0;i<splitarr.length;i++)
        {
            if(splitarr[i].key!=="99999")
            {
                    SplitKeys.push(splitarr[i].key)
                    position.push(splitarr[i].position)
                    
                }
        }
        //sorting the splits based on position
        for (let i = 1; i < position.length; i++) {
            for (let j = i; j > 0; j--) {
             if (position[j] < position [j - 1]) {
              let temp = position[j];
              let temp2=SplitKeys[j]
              position[j] = position[j - 1];
              SplitKeys[j] = SplitKeys[j - 1];
              position[j - 1] = temp;
              SplitKeys[j - 1] = temp2;
             }
            }
           }
        return SplitKeys
    }
    getSplitname(splitKey,DimSplit){
        for(let i=0;i<DimSplit.length;i++)
        {
            if(parseInt(DimSplit[i].SplitKey)===parseInt(splitKey))
            {
                return DimSplit[i].SplitName
            }
        }
    }
    getPodName(key,DimSplit){
        for(let i=0;i<DimSplit.length;i++)
        {
            if(parseInt(DimSplit[i].PodKey)===parseInt(key))
            {
                return DimSplit[i].PodName
            }
        }
    }
}

export default getSplitKeys