import "../assets/css/dashboard_print.css";
import "../assets/css/text.css";
import { withOktaAuth } from "@okta/okta-react";
import React, { Component } from "react";
import CallApis from "../DataServices/CallApis";
import { checkAuthentication } from "../DataServices/helper";

import getSplitKeys from "../DataServices/getSplitKeys";
import DisplayBoardAgentTable from "../components/DisplayBoardsComponent/DisplayBoardAgentTable";
import DisplayBoardTiles from "../components/DisplayBoardsComponent/DisplayBoardTiles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import DisplayBoardAgentHeader from "../components/DisplayBoardsComponent/DisplayBoardAgentHeader";
class MultiClinicBoard extends Component {
  intervalID = 0;
  constructor(props) {
    super(props);
    this.state = {
      boardId: "",
      RealtimeBoardSettingsIndivisual: [],
      DimSplit: [],
      RealTimeSplits: [],
      RealTimeAgents: [],
      time1: ["", ""],
      time2: ["", ""],
      errorMessage: "",
      phoneHub: null,
      redirectAlert: false,
      authenticated: null,
      userInfo: null,
      timeout: 60,
      flag: 0,
      staticFlag: false,
      DataStatus: "",
      DataStatusMessage: "",
    };
    this.checkAuthentication = checkAuthentication.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }
  async login() {
    this.props.authService.login("/displayboard");
  }

  async logout() {
    // this.props.authService.logout("/");
  }
  async callapi() {
    // if (this.state.e !== "") {
    //   return;
    // }
    var currentdate = new Date().toLocaleTimeString();
    console.log("time before call"+currentdate);
    const AccessToken = await this.props.authService.getAccessToken();
    if (!this.state.staticFlag) {
      CallApis.prototype
        .getStaticNewDisplayBoard(AccessToken, this.state.boardId)
        .then((response) => {
          var currentdate = new Date().toLocaleTimeString();
          console.log("time after call"+currentdate);
          let {
            Splits = [],
            DimSplits = [],
            Agents = [],
            RealTimeBoardSettings = [],
            DatabaseDateTime = "",
            DataStatus = "",
            DataStatusMessage = "",
          } = response;
          console.log("first response", { response });
          if (Splits !== null) {
            this.setState({
              DataStatus,
              DataStatusMessage,
              errorMessage: "",
              staticFlag: true,
              DimSplit: DimSplits,
              RealTimeAgents: Agents,
              RealTimeSplits: Splits,
              RealtimeBoardSettingsIndivisual: RealTimeBoardSettings,
              time1:
                DatabaseDateTime != "" ? DatabaseDateTime.split("T") : ["", ""],
              time2:
                Agents.length != 0
                  ? Agents[0].UpdatedDateTime.split("T")
                  : ["", ""],
            });
          } else {
            this.setState({
              DataStatus,
              DataStatusMessage,
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (this.state.timeout == 60) {
            setInterval(async () => {
              let tim = this.state.timeout;
              if (tim != 0)
                this.setState({
                  timeout: tim - 3,
                  flag: 1,
                });
              else {
                window.location.reload(false);
              }
              if (this.state.timeout == 0) {
                this.callapi();
                this.setState({
                  timeout: 60,
                  flag: 0,
                });
              }
            }, 3000);
          }

          this.setState({
            loader: false,
            redirectAlert: true,
            errorMessage: err,
          });
        });
    }
    CallApis.prototype
      .getNewDisplayBoard(AccessToken, this.state.boardId)
      .then((response) => {
        console.log("kk",response);
        var currentdate = new Date().toLocaleTimeString();
        console.log("time after call"+currentdate);
        let {
          Splits = [],
          DimSplits = [],
          Agents = [],
          RealTimeBoardSettings = [],
          DatabaseDateTime = "",
          DataStatus = "",
          DataStatusMessage = "",
        } = response;
        if (Splits !== null && Splits.length !== 0) {
          this.setState({
            DataStatus,
            DataStatusMessage,
            errorMessage: "",
            DimSplit: DimSplits,
            RealTimeSplits: Splits,
            RealtimeBoardSettingsIndivisual: RealTimeBoardSettings,
            time1:
              DatabaseDateTime != "" ? DatabaseDateTime.split("T") : ["", ""],
          });
        }
        if (Agents !== null && Agents.length !== 0) {
          this.setState({
            RealTimeAgents: Agents,
            time2:
              Agents.length != 0
                ? Agents[0].UpdatedDateTime.split("T")
                : ["", ""],
          });
        } else {
          if (!DataStatus.toUpperCase().includes("COMPLETED")) {
            this.setState({
              DataStatus,
              DataStatusMessage,
              errorMessage: { message: DataStatusMessage },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (this.state.timeout == 60) {
          setInterval(async () => {
            let tim = this.state.timeout;
            if (tim != 0)
              this.setState({
                timeout: tim - 3,
                flag: 1,
              });
            else {
              window.location.reload(false);
            }
          }, 3000);
        }

        this.setState({
          loader: false,
          redirectAlert: true,
          errorMessage: err,
        });
      });
  }
  componentDidUpdate() {
    // this.checkAuthentication();
  }
  componentWillUnmount() {
    //   console.log('hii')
    //    clearInterval(this.intervalID)
  }
  componentDidMount() {
    this.checkAuthentication();
    this.intervalID = setInterval(async () => {
      this.callapi();
    }, 5000);
    // if(this.state.timeout==60)
    //     {
    //         setInterval(async ()=>{
    //        let tim=this.state.timeout
    //        if(tim!=0)
    //    this.setState({
    //        timeout:tim-3,
    //        flag:1
    //     })
    //     }, 3000);
    //     }
  }
  handleAlertClose() {
    if (this.state.redirectAlert) {
      this.setState({ redirectAlert: false });
      window.location.reload();
    } else {
      this.setState({ redirectAlert: true });
    }
  }
  fillSplitTilesData(splitKeys) {
    let RealTimeSplits = this.state.RealTimeSplits;
    let names = [];
    let callsInQueues = [];
    let oldestCallWaitings = [];
    let abandons = [];
    let agentsAvailables = [];
    let agentsStaffeds = [];
    let averageSpeedOfAnswers = [];
    let averageSpeedOfAnswerr = "";
    let agentsAvailablee = "";
    let agentsStaffedd = "";
    let name = "";
    let callsInQueue = "";
    let oldestCallWaitingg = "";
    let abandon = "";
    let splitKeyy = "";
    let splitkeyarr = [];
    let iofdecimal = -1;

    for (let j = 0; j < splitKeys.length; j++) {
      for (let i = 0; i < RealTimeSplits.length; i++) {
        const {
          CallsWaiting = "0",
          OldestCallWaiting = "00:00",
          AbandonedCalls = "0",
          PodKey = "0",
          AgentsAvailable = "0",
          AgentsStaffed = "0",
          AverageSpeedOfAnswer = "00:00",
        } = RealTimeSplits[i];
        if (splitKeys[j] === "0") {
          name = "Unknown";
          callsInQueue = "0";
          oldestCallWaitingg = "00:00";
          abandon = "0";
          splitKeyy = "0";
          agentsAvailablee = "0";
          agentsStaffedd = "0";
          averageSpeedOfAnswerr = "00:00";
        }
        if (parseInt(RealTimeSplits[i].PodKey) === parseInt(splitKeys[j])) {
          name = getSplitKeys.prototype.getPodName(
            splitKeys[j],
            this.state.DimSplit
          );
          callsInQueue = CallsWaiting;
          oldestCallWaitingg = OldestCallWaiting.substring(3);
          abandon = AbandonedCalls || "0";
          splitKeyy = PodKey;
          agentsAvailablee = AgentsAvailable;
          agentsStaffedd = AgentsStaffed;
          averageSpeedOfAnswerr = AverageSpeedOfAnswer.substring(3);
          iofdecimal = averageSpeedOfAnswerr.lastIndexOf(".");
          if (iofdecimal !== -1) {
            averageSpeedOfAnswerr = averageSpeedOfAnswerr
              .substring(0, iofdecimal)
              .concat(averageSpeedOfAnswerr.substr(iofdecimal, 3));
          }

          break;
        } else {
          name = getSplitKeys.prototype.getPodName(
            splitKeys[j],
            this.state.DimSplit
          );
          callsInQueue = "0";
          oldestCallWaitingg = "00:00";
          abandon = "0";
          splitKeyy = splitKeys[j];
          agentsAvailablee = "0";
          agentsStaffedd = "0";
          averageSpeedOfAnswerr = "00:00";
        }
      }
      names.push(name);
      callsInQueues.push(callsInQueue);
      oldestCallWaitings.push(oldestCallWaitingg);
      abandons.push(abandon);
      splitkeyarr.push(splitKeyy);
      agentsAvailables.push(agentsAvailablee);
      agentsStaffeds.push(agentsStaffedd);
      averageSpeedOfAnswers.push(averageSpeedOfAnswerr);
    }

    return [
      names,
      callsInQueues,
      oldestCallWaitings,
      abandons,
      splitkeyarr,
      agentsAvailables,
      agentsStaffeds,
      averageSpeedOfAnswers,
    ];
  }
  createAgentTables() {
    let agentArr = [];
    let splitArr = [];
    const {
      splits = [],
      acdwarningtime = "",
      acdalerttime = "",
      auxreasons = [],
    } = this.state.RealtimeBoardSettingsIndivisual[0].BoardSetting;
    let splitKeys = getSplitKeys.prototype.getSplitKey(splits);
    let RealTimeAgent = this.state.RealTimeAgents;
    let acdwarningtimes = acdwarningtime;
    let acdalerttimes = acdalerttime;
    for (let i = 0; i < RealTimeAgent.length; i++) {
      const {
        AuxAltKey = "0",
        Direction = "",
        AuxInstances = "",
        WorkSplitKey = "",
        SplitName = "",
        TimeInState = "",
        AuxReason = "",
        AgentName = "",
        ACDCallsTotal = "0",
        ACDTimeTotal = "0",
        ACDTime = "",
        WorkMode = "",
        AuxTime = "",
        AuxTimeTotal = "",
      } = RealTimeAgent[i];

      if(this.state.boardId==8)
      {
        agentArr.push(
          <DisplayBoardAgentTable
            auxreasons={auxreasons}
            auxReasonKey={AuxAltKey}
            Direction={Direction}
            auxInstance={AuxInstances}
            AuxTimeTotal={AuxTimeTotal}
            boardSettingsAcdWarningTime={acdwarningtimes}
            boardSettingsAcdAlertTime={acdalerttimes}
            time={this.state.time}
            splitKey={WorkSplitKey}
            splitName={SplitName}
            duration={TimeInState}
            RealTimeAgent={RealTimeAgent}
            auxReason={AuxReason}
            agentName={AgentName}
            acd={ACDCallsTotal}
            justACDTime={ACDTime}
            acdTime={ACDTimeTotal}
            workMode={WorkMode}
            AuxTime={AuxTime}
            boardId={this.state.boardId}
          />
        );
      }
      else
      {
        agentArr.push(
          <DisplayBoardAgentTable
            auxreasons={auxreasons}
            auxReasonKey={AuxAltKey}
            Direction={Direction}
            // auxInstance={AuxInstances}
            // AuxTimeTotal={AuxTimeTotal}
            boardSettingsAcdWarningTime={acdwarningtimes}
            boardSettingsAcdAlertTime={acdalerttimes}
            time={this.state.time}
            splitKey={WorkSplitKey}
            splitName={SplitName}
            duration={TimeInState}
            RealTimeAgent={RealTimeAgent}
            auxReason={AuxReason}
            agentName={AgentName}
            acd={ACDCallsTotal}
            justACDTime={ACDTime}
            acdTime={ACDTimeTotal}
            workMode={WorkMode}
            AuxTime={AuxTime}
            boardId={this.state.boardId}
          />
        );
      }

    }
    return agentArr;
  }
  reconnect() {
    setTimeout(function () {
      window.location.reload(false);
    }, 60000);
    return null;
  }
  render() {
    if (this.state.boardId === "") {
      let url = window.location.href;
      url = new URL(url);
      let id = url.searchParams.get("id");
      this.setState({ boardId: id });
    } else if (this.state.RealtimeBoardSettingsIndivisual.length === 0) {
      this.callapi();
    }
    let data = [];
    let noofsplits = 0;
    if (this.state.RealtimeBoardSettingsIndivisual[0] !== undefined) {
      const { splits = [] } =
        this.state.RealtimeBoardSettingsIndivisual[0].BoardSetting;
      noofsplits = getSplitKeys.prototype.getSplitKey(splits).length;
      if (this.state.RealTimeSplits.length === 0) {
        noofsplits = 0;
      }
      data = this.fillSplitTilesData(
        getSplitKeys.prototype.getSplitKey(splits)
      );
    }
    if (this.state.authenticated === null)
      return <div style={{ width: "100%" }}></div>;
    if (this.state.authenticated) {
      // if(this.state.errorMessage!=='')
      // {

      //     return(
      //         <div style={{ width: '100%' }}>
      //         <div id="contentwrapper" style={{ textAlign: "center" }}>
      //         {this.reconnect()}
      //         <span style={{ fontSize: 30, color: 'red' }}>{this.state.errorMessage.message+' '+`Server disconnected, trying to reconnect in ${this.state.timeout} sec …`}</span>
      //         </div>
      //         </div>
      //     )
      // }
      return (
        <div style={{ width: "100%", margin: 0, left: 0, right: 0 }}>
          <div
            id="contentwrapper"
            style={{ textAlign: "center", padding: "1em", width: "100%" }}
          >
            {data.every((el) => el.length !== 0) ? (
              DisplayBoardTiles.prototype.createTiles(
                noofsplits,
                data,
                this.state.RealtimeBoardSettingsIndivisual,
                this.state.time1,
                this.state.DataStatus,
                this.state.DataStatusMessage,
                this.state.errorMessage,
                this.state.timeout
              )
            ) : this.state.errorMessage !== "" ? (
              <span style={{ fontSize: 30, color: "red", width: "100%" }}>
                {this.state.errorMessage.message}
              </span>
            ) : (
              <div style={{ textAlign: "center" }}>
                <div style={{ display: "inline-block" }}>
                  <ThemeProvider theme={theme}>
                    <CircularProgress style={{ margin: "50%" }} />
                  </ThemeProvider>
                </div>
              </div>
            )}
            <br />
            <table
              border="2"
              style={{ borderColor: "#e8f7fc" }}
              cellspacing="1"
              cellpadding="5"
              width="100%"
              id="AgentsTableData"
            >
              {data.length !== 0 &&
              this.state.RealtimeBoardSettingsIndivisual[0] !== undefined &&
              this.state.RealTimeAgents.length != 0 ? (
                <DisplayBoardAgentHeader 
                  count={this.state.RealTimeAgents.length}
                  boardId={this.state.boardId}
                />
              ) : null}
              {data.every((el) => el.length !== 0) &&
              this.state.RealtimeBoardSettingsIndivisual[0] !== undefined &&
              this.state.RealTimeAgents.length !== 0
                ? this.createAgentTables()
                : null}
              <tr>
                {data.every((el) => el.length !== 0) &&
                this.state.RealtimeBoardSettingsIndivisual[0] !== undefined &&
                this.createAgentTables().length != 0 &&
                this.state.time1[0] != "" ? (
                  <td colspan="9" align="left">
                    Data refreshed every 5 seconds. Last update:{" "}
                    {this.state.time1[0].split("-")[1] +
                      "/" +
                      this.state.time1[0].split("-")[2] +
                      "/" +
                      this.state.time1[0].split("-")[0] +
                      " " +
                      ((parseInt(this.state.time1[1].substring(0, 2)) > 12
                        ? parseInt(this.state.time1[1].substring(0, 2)) - 12
                        : parseInt(this.state.time1[1].substring(0, 2))) +
                        this.state.time1[1].substring(2, 8) +
                        " " +
                        (parseInt(this.state.time1[1].substring(0, 2)) >= 12
                          ? "PM"
                          : "AM"))}
                  </td>
                ) : null}
              </tr>
            </table>
          </div>
        </div>
      );
    }
  }
}
const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiCircularProgress: {
      // Name of the rule
      colorPrimary: {
        // Some CSS
        color: "grey",
        opacity: 0.5,
      },
    },
  },
});
export default withOktaAuth(MultiClinicBoard);
