import React, { useEffect, useState,useRef } from "react";
import { Container, Row, Col, Card,CardBody } from "shards-react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { withOktaAuth } from "@okta/okta-react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from '@material-ui/core/Divider'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import DeleteConfirmDialog from '../components/DisplayBoardsComponent/DeleteConfirmDialog'
import CreatePod from './CreatePod';
import CallApis from '../DataServices/CallApis';


function SecondaryText({ pod }) {
  let createdDtm = pod.createdDtm !== null ? new Date(pod.createdDtm) : null;
  let formattedDate = createdDtm
    ? `${createdDtm.getDate()}-${
        createdDtm.getMonth() + 1
      }-${createdDtm.getFullYear()}`
    : "N/A";
  let createdBy = pod.createdBy;
  let relatedBoards = pod.realTimeBoards.map(board=> board.boardName).join(", ");
  return (
    <ul style={{listStyleType:'none',fontFamily:'Poppins',paddingLeft:'0.2em'}}>
      <li><span style={{fontWeight:'bold',fontFamily:'Poppins'}}>Splits:&nbsp;</span>
        {pod.splits.length > 10 ? (
          <span style={{ color: "#ea1d36",fontFamily:'Poppins' }}>Too many splits to display</span>
      ) : pod.splits.length === 0 ? (
        <span style={{color:'#ea1d36',fontFamily:'Poppins'}}>no splits</span>
      ) : (
          <span>{pod.splits.join(", ")}</span>
      )}
      </li>
      <li><span style={{fontWeight:'bold',fontFamily:'Poppins'}}>Boards:</span>&nbsp;<span style={{fontSize:'1.2em',fontFamily:'Poppins'}}>{relatedBoards}</span></li>
      <li>creator:&nbsp;{createdBy}&ensp;created-Date:&nbsp;{formattedDate}</li>
    </ul>
  );
}


function PodLists({pods, handleEdit,handleDelete,podKey}){
  return <List style={{ overflowY: "auto",fontFamily:'Poppins' }} dense>
  {pods[0].map((pod,index) => (
    <div key={pod.podKey}>
      <ListItem style={{fontFamily:'Poppins'}} key={pod.podKey} selected={pod.podKey===podKey}>
        <ListItemText
        style={{fontFamily:'Poppins'}}
          primary={<Typography variant="h6" style={{fontFamily:'Poppins'}}>{pod.podName}</Typography>}
          secondaryTypographyProps={{component:'div'}}
          secondary={
           <SecondaryText style={{fontFamily:'Poppins'}} pod={pod}/>
          }
        />
        <ListItemSecondaryAction style={{fontFamily:'Poppins'}}>
          <IconButton style={{border:'none',outline:'none'}} onClick={handleEdit(pod)} disableRipple={true}>
            <img
              src={require("../assets/images/edit.png")}
              alt="Edit"
              title="Edit"
              border="0"
              width="16"
              height="16"
            />
          </IconButton>
          <IconButton edge="end" style={{border:'none',outline:'none'}} onClick={handleDelete(pod.podKey,pod.podName)} disableRipple={true}>
            <img
              src={require("../assets/images/delete.png")}
              alt="Delete"
              width="16"
              height="16"
              title="Delete"
              border="0"
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {index!==pods[0].length-1?<Divider component="li" />:null}
    </div>
  ))}
</List>
}



function ManagePod(props) {
  const [pods, setPods] = useState([]);
  const [editPanel,setEditPanel] = useState(false);
  const [openDiag, setDiag] = useState(false);
  const [targetPod, setTargetPod] = useState("");
  const [podKey,setPodKey] = useState(null);
  const [podmeta,setpodmeta]= useState(null);
  const [activeClinics, setActiveClinics] = useState([]);
  const [activeSplits, setActiveSplits] = useState([]);
  const [responseMessages, setResponseMesssage] = useState({status:"",message:""});
  const delKey = useRef(null);
  useEffect(() => {
    let {podData} = props;
    setPods(podData);
  }, []);
  const deletePod = ()=>{
    let token = props.authState.accessToken;
    CallApis.prototype.deletePod(token,delKey.current)
      .then(res=>{
        if(res)
          {
            setDiag(false);
            setResponseMesssage({status:'PASS', message:"POD deleted successfully"});
            props.recall();
          }
      })
      .catch(err=>{
        delKey.current=null;
        setDiag(false);
        setResponseMesssage({status:"FAIL", message:err.response.data.Message});
      })
  }
  const handleDelete = (podKey,podName)=>e=>{
    delKey.current = podKey;
    setPodKey(podKey);
    setTargetPod(podName);
    setDiag(true);
  }
  const handleEdit = pod=>e=>{
    e.preventDefault();
    setPodKey(pod.podKey);
    setEditPanel(true);
    setTargetPod(pod.podName);
    setpodmeta({createdBy:pod.createdBy,createdDtm:pod.createdDtm});
    setActiveClinics(pod.clinics);
    setActiveSplits(pod.splits);
  }
  const handleEditClose = ()=>{
    setPodKey(null);
    setEditPanel(false);
  }
  return (
    <Container fluid className="main-content-container px-4">
      {responseMessages.message !=="" ?<Card small className="md-3" style={{ margin: 20, textAlign: "center",fontFamily:'Poppins' }}>
          <CardBody className=" d-flex justify-content-between align-items-center">
            <span style={{ fontWeight: "bolder", textAlign: 'center',width:'100%',fontFamily:'Poppins',color: responseMessages.status!=='FAIL'?'#004977':'#ea1d36' }}>{responseMessages.message}
            </span>
            <IconButton style={{float:'right',fontFamily:'Poppins'}} onClick={()=>setResponseMesssage({status:'',message:''})} size='small'>
                <HighlightOffIcon/>
            </IconButton>
          </CardBody>
        </Card> : null}
      <Row>
        <Col lg="5" className="mt-2 mb-4">
          <Card
            small
            style={{
              paddingBottom: "2px",
              maxHeight: "85vh",
            }}
          >
            {pods.length !== 0 && <PodLists pods={pods} handleDelete={handleDelete} handleEdit={handleEdit} podKey={podKey}/>}
          </Card>
        </Col>
        <Col className="mt-2 mb-4">
          <Fade in={editPanel}>
            <div>
              {
                editPanel && <CreatePod 
                podname={targetPod}
                hideHeader={true}
                clinicData={props.clinicData} 
                splitData={props.splitData}
                selectedclinics= {activeClinics}
                selectedsplits= {activeSplits}
                podKey={podKey}
                oncancel={handleEditClose}
                metadata={podmeta}
                recall={props.recall}
                type="put"
                expandName
              />
              }
              </div>
          </Fade>
        </Col>
      </Row>
      <DeleteConfirmDialog open={openDiag} handleClose={()=>setDiag(false)} Agree={deletePod} boardName={targetPod} isPod />
    </Container>
  );
}
export default withOktaAuth(ManagePod);
