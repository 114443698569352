import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
const SidebarNavItem = ({ item }) => (
  <NavItem>
    <NavLink
  className="nav-link"
  activeClassName="active"
    activeStyle={{ fontWeight: "bold", color: "#0D9DDB" }}
    tag={RouteNavLink} to={item.to}>
      {item.htmlBefore && (
        <div
          className="d-inline-block item-icon-wrapper"
        >
          {item.icon==='settings'?<SettingsIcon/>:<AssessmentIcon/>}
          </div>
      )}
      {item.title && <span >{item.title}</span>}
      {item.htmlAfter && (
        <div
          className="d-inline-block item-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
        >

          </div> 
      )}
    </NavLink>
  </NavItem>
);

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
