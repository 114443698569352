import React, { Component } from 'react';

class DisplayBoardAgentHeader extends Component {
  render() {
    return (
      <>
        <tr>
          <td colspan="9" style={{fontFamily:'Poppins'}} class="agents-header" align="left">Agents ({this.props.count}) </td>
        </tr>
        <tr style={{fontFamily:'Poppins'}}>
          <td class="agents-table-header" align="left">Agents</td>
          <td class="agents-table-header" align="left">Split</td>
          <td class="agents-table-header" align="center">ACD Calls</td>
          <td class="agents-table-header" align="center">ACD Time</td>
          <td class="agents-table-header" align="center">Work Mode</td>
          <td class="agents-table-header" align="center">Aux Reason</td>
          {this.props.boardId==8?<td class="agents-table-header" align="center">#Aux Instances</td>:""}
          {this.props.boardId==8?<td class="agents-table-header" align="center">Aux Time Total</td>:""}
          <td class="agents-table-header" align="center">Time in State</td>
        </tr>
      </>
    );
  }
}

export default DisplayBoardAgentHeader;
