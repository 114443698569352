import React, { Component } from 'react';
import Button2 from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
class DeleteConfirmDialog extends Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => { this.props.handleClose() }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.isPod?"Delete Pod?":"Delete Board?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style= {{fontFamily:'Poppins' }}>
          Are you sure you want to delete {this.props.boardName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button2 onClick={() => { this.props.handleClose() }} color="primary" style={{border:'none',outline:'none',fontFamily:'Poppins'}}>
            Disagree
          </Button2>
          <Button2 onClick={() => { this.props.Agree() }} color="primary" autoFocus style={{border:'none', outline:'none',fontFamily:'Poppins'}}>
            Agree
          </Button2>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeleteConfirmDialog;