import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
/**
 * @augments {Component<Props, State>}
 */
class PodSettingSplit extends Component {
  render() {
    return (
      <Grid item style={{display:'flex', flexDirection:'row',width:'15rem'}}>
          <Checkbox
            checked={
              this.props.splitcheckbox === undefined
                ? false
                : this.props.splitcheckbox
            }
            onChange={(event) => {
              this.props.handleSplitChange(
                event.target.checked,
                this.props.splitKey,
                "checkbox"
              );
            }}
            color="primary"
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
            id={`id_for_${this.props.splitName}`}
          />
          <div style={{display:'flex',flexDirection:'column', justifyContent:'center'}}>
            <Typography style={{fontFamily:'Poppins'}} component='label' htmlFor={`id_for_${this.props.splitName}`}>{this.props.splitName}</Typography>
        </div>
        </Grid>
    );
  }
}
PodSettingSplit.propTypes = {
  splitcheckbox: PropTypes.bool.isRequired,
  handleSplitChange: PropTypes.func,
  splitName: PropTypes.string.isRequired,
  splitKey: PropTypes.number.isRequired,
};
export default PodSettingSplit;
