import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  FormInput,
} from "shards-react";
import Checkbox from '@material-ui/core/Checkbox';
class DisplayBoardsSettingsAux extends Component {
  render() {
    return (
      <tr>
        <td align="left" colSpan="6"><FormControlLabel
          control={
            <Checkbox
              checked={this.props.auxCheckBox === undefined ? false : this.props.auxCheckBox}
              onChange={(event) => { this.props.handleAuxChange(event.target.checked, this.props.auxKey, "checkbox") }}
              value="checkedB"
              color="primary"
            />
          }
          label={<span style={{fontFamily:'Poppins'}}>{this.props.auxName}</span>}
        /></td>
        <td align="right" colSpan="2"><FormInput type="number" disabled={!this.props.auxCheckBox} style={{ backgroundColor: '#FFFD82', fontStyle: 'bold', color: '#000', fontWeight: 'bold', width: '40%', paddingRight: "0%", fontFamily:'Poppins' ,textAlign: 'center' }} name="AuxWarningSeconds" size="1" className={`warning-textbox `} value={this.props.auxCheckBox ? this.props.auxWarning : ''} onChange={(event) => { this.props.handleAuxChange(event.target.value, this.props.auxKey, "warning") }} /></td>
        <td align="right" colSpan="2"><FormInput type="number" disabled={!this.props.auxCheckBox} style={{ backgroundColor: '#ea1d36', fontStyle: 'bold', color: '#000', fontWeight: 'bold', width: '40%', paddingRight: "0%", fontFamily:'Poppins' ,textAlign: 'center' }} name="AuxAlertSeconds" size="1" className='alert-textbox' value={this.props.auxCheckBox ? this.props.auxAlert : ''} onChange={(event) => { this.props.handleAuxChange(event.target.value, this.props.auxKey, "alert") }} /></td>
      </tr>
    );
  }
}

export default DisplayBoardsSettingsAux;
