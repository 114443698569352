import React, { Component } from "react";
import { FormInput } from "shards-react";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
/**
 * @augments {Component<Props, State>}
 */
class PodSettingsSplit extends Component {
  render() {
    return (
      <tr>
        <td style={{ width: "20%" }}>
          <label for="700" style={{ fontWeight: "normal",  
                              fontFamily:'Poppins',}}>
            {this.props.splitName}
          </label>
        </td>
        <td>
          <FormInput
            type="number"
            style={{
              width: "63%",
              fontWeight: "bold",
              alignItems: "center",
              paddingRight: "0%",
              textAlign: "center",
              fontFamily:'Poppins',

            }}
            className={`textbox ${`position${""}`}`}
            name="Position"
            size="1"
            value={this.props.position || ""}
            onChange={(event) => {
              this.props.handleSplitChange(
                event.target.value,
                this.props.splitKey,
                "position"
              );
            }}
          />
        </td>
        <td align="left">
          <table border="0" cellspacing="0" cellpadding="2">
            <tr>
              <td align="right">
                <b>
                  <FormInput
                    type="number"
                    className={`callswaitingwarning${""}`}
                    style={{
                      backgroundColor: "#FFFD82",
                      fontStyle: "bold",
                      color: "#000",
                      width: "65%",
                      fontFamily:'Poppins',
                      fontWeight: "bold",
                      paddingRight: "0%",
                      textAlign: "center",
                    }}
                    value={this.props.callsWaitingWarning || ""}
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "callwarning"
                      );
                    }}
                  />
                </b>
              </td>
              <td align="left">
                <b>
                  <FormInput
                    type="number"
                    className={`callswaitingalert${""}`}
                    style={{
                      backgroundColor: "#ea1d36",
                      fontStyle: "bold",
                      fontFamily:'Poppins',
                      color: "#000",
                      width: "65%",
                      fontWeight: "bold",
                      paddingRight: "0%",
                      textAlign: "center",
                    }}
                    value={ this.props.callsWaitingAlert || "" }
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "callalert"
                      );
                    }}
                  />
                </b>
              </td>
            </tr>
          </table>
        </td>
        <td align="left">
          <table border="0" cellspacing="0" cellpadding="2">
            <tr>
              <td align="right">
                <b>
                  <FormInput
                    type="number"
                    className={`oldestcallwaitingwarning${""}`}
                    style={{
                      backgroundColor: "#FFFD82",
                      fontStyle: "bold",
                      color: "#000",
                      width: "65%",
                      fontWeight: "bold",
                      fontFamily:'Poppins',
                      paddingRight: "0%",
                      textAlign: "center",
                    }}
                    value={ this.props.oldestcallsWaitingWarning ||  "" }
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "oldestcallwarning"
                      );
                    }}
                  />
                </b>
              </td>
              <td align="left">
                <b>
                  <FormInput
                    type="number"
                    className={`oldestcallwaitingalert${""}`}
                    style={{
                      backgroundColor: "#ea1d36",
                      fontStyle: "bold",
                      fontFamily:'Poppins',
                      color: "#000",
                      width: "65%",
                      fontWeight: "bold",
                      paddingRight: "0%",
                      textAlign: "center",
                    }}
                    value={ this.props.oldestcallsWaitingAlert|| "" }
                    onChange={(event) => {
                      this.props.handleSplitChange(
                        event.target.value,
                        this.props.splitKey,
                        "oldestcallalert"
                      );
                    }}
                  />
                </b>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    );
  }
}
PodSettingsSplit.propTypes = {
  handleSplitChange: PropTypes.func,
  splitName: PropTypes.string.isRequired,
  splitKey: PropTypes.number.isRequired,
  position: PropTypes.number,
  callsWaitingWarning:PropTypes.number,
  callsWaitingAlert: PropTypes.number,
  oldestcallsWaitingWarning: PropTypes.number,
  oldestcallsWaitingAlert: PropTypes.number,

};
export default PodSettingsSplit;
