import React,{Component} from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SecureRoute, LoginCallback,Security } from "@okta/okta-react"

import routes from "./routes";
import config from './config';

import "bootstrap/dist/css/bootstrap.min.css";

import './assets/css/shards-dashboards.1.1.0.min.css'
const okatConfig = {
  issuer:  config.APP_ISSUER,
  redirect_uri: `${window.location.origin}/login/callback`,
  client_id: config.APP_CLIENT_ID||"",
};
class App extends Component {
  
  render() {
    if(okatConfig.client_id==""||okatConfig.client_id==undefined||okatConfig.issuer==""||okatConfig.issuer==undefined)
    {
      return (
        <div>
          <span>please see you have configured the Okta properly in the .env file</span>
        </div>
      )
    }
    return (
      <Router>
        <Security {...okatConfig}>
          <div>
          {routes.map((route, index) => {
            return (
                        <SecureRoute
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          handler={route.handler}
                          component={(props)=>{
                            return(
                              <route.layout {...props}>
                              <route.component {...props} />
                            </route.layout>
                            )   
                          }
                          }
                        />
                      );
                        })}
            <Route path="/login/callback" component={LoginCallback} />
          </div>
        </Security>
      </Router>
    );
  }
}
export default App;

