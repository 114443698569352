import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../'
import '../../assets/css/shards-dashboards.1.1.0.min.css'
import { withOktaAuth } from '@okta/okta-react'
import CallApis from "../../DataServices/CallApis";

class MainFooter extends Component{
  constructor(props)
  {
    super(props)
    this.state={
      version:'',
      databaseDetails:''
    }
  }
  async callApi(){
    const AccessToken = await this.props.authService.getAccessToken();
    CallApis.prototype.getVersionAndDatabase(AccessToken).then((response)=>{
      this.setState({version:response.Version,databaseDetails:response.Database})
    }).catch((err)=>{
      console.log(err.response)
    })
  }
  render(){
    if(this.state.version=='')
    {
      this.callApi()
    }
    return (
      <footer
        className="main-footer d-flex p-2 px-3 border-top"
        style={{
          background:
            "linear-gradient(to bottom, rgba(221,231,238,1) 0%, rgba(244,248,251,1) 100%)",
          filter:
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#dde7ee', endColorstr='#f4f8fb', GradientType=0 )",
          justifyContent:'space-around'
        }}
      >
        {/* <Row> */}
        <span className="copyright">
          All Rights Reserved.Copyright © Children's Hospital Los Angeles 2020
        </span>
        <span className="copyright" style={{wordWrap:'break-word'}}>
          {this.state.databaseDetails} / Version: {this.state.version}
        </span>
        {/* </Row> */}
      </footer>
    );
    
  }
}

 export default withOktaAuth( MainFooter);
