import React, { Component } from "react";
import { Row, Col, Button } from "shards-react";
import PageTitle from "../DisplayBoardsComponent/PageTitle";
import PropTypes from "prop-types";
/**
 * @author Aditya Poddar
 * @augments {Component<Props,State>}
 */
class DisplayBoardSettingsButtons extends Component {
  render() {
    return (
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={this.props.title}
          className="text-sm-left mb-3"
        />
        <Col style={{ float: "right", alignItems: "center" }}>
          <span style={{ float: "right", justifyContent: "space-between" }}>
            <Button
              disabled={this.props.disabled}
              style={{
                backgroundColor: "#4daa50",
                fontFamily: "Poppins",
                borderRadius: 100,
                borderColor: "white"
              }}
              onClick={() => {
                if (this.props.location.state != undefined) {
                  if (this.props.location.state.type == "put") {
                    this.props.putData();
                  } else {
                    this.props.postData();
                  }
                } else {
                  this.props.postData();
                }
              }}
            >
              {this.props.saveBtnTitle}{" "}
            </Button>
            &nbsp;&nbsp;
            {!this.props.disabled && (
              <Button
                active
                style={{ backgroundColor: "#ea1d36", fontFamily: "Poppins" ,borderRadius:100,borderColor:"white"}}
                href={this.props.goOnCancel}
              >
                Cancel{" "}
              </Button>
            )}
          </span>
        </Col>
      </Row>
    );
  }
}

export default DisplayBoardSettingsButtons;

DisplayBoardSettingsButtons.propTypes = {
  postData: PropTypes.func.isRequired,
  putData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired
};
