import React, { useState, useEffect } from "react";
import { Route, NavLink } from "react-router-dom";
import { Nav, NavItem, Container } from "shards-react";
import CreatePod from "./CreatePod";
import ManagePod from "./ManagePod";
import { withOktaAuth } from "@okta/okta-react";
import CallApis from "../DataServices/CallApis";

function PodSettingsManager(props) {
  let { match } = props;
  const [clinics, setclinics] = useState([]);
  const [pods, setpods] = useState([]);
  const [splits, setsplits] = useState([]);
  let token = props.authState.accessToken;
  const prepareData = async () => {
    let clinicRequest = CallApis.prototype.createDimClinic(token);
    let splitRequest = CallApis.prototype.createDimSplit(token);
    let podRequest = CallApis.prototype.getAllPods(token);
    let [clinics, splits, pods] = await Promise.all([
      clinicRequest,
      splitRequest,
      podRequest,
    ]);
    let expression = new RegExp("\\d+");
    let modifiedSplitList = splits.map((split) => {
      let splitKeyInName = split.splitName.match(expression);
      if (splitKeyInName === null)
        return { ...split, splitName: split.splitName + " " + split.splitKey };
      else return { ...split };
    });
    setclinics(clinics);
    setpods(pods);
    setsplits(modifiedSplitList);
  };

  useEffect(() => {
    prepareData();
  }, []);

  return (
    <Container fluid className="main-content-container px-4 py-2">
      <Nav className="nav nav-tabs">
        <NavItem className="nav-item">
          <NavLink
            className="nav-link"
            activeClassName="active"
            activeStyle={{ fontWeight: "bold", color: "#0D9DDB" }}
            to={`${match.url}`}
            exact
          >
            Create Pod
          </NavLink>
        </NavItem>
        <NavItem className="nav-item">
          <NavLink
            className="nav-link"
            activeClassName="active"
            activeStyle={{ fontWeight: "bold", color: "#0D9DDB" }}
            to={`${match.url}/manage`}
          >
            Manage Pods
          </NavLink>
        </NavItem>
      </Nav>
      {splits.length !== 0 && clinics.length !== 0 ? (
        <div>
          <Route
            path={match.path}
            exact
            component={(props) => (<CreatePod type="post" clinicData={clinics} splitData={splits} recall={prepareData} {...props}/>
            )}
          />
          <Route
            path={match.path + "/manage"}
            component={() => (<ManagePod podData={pods} clinicData={clinics} recall={prepareData} splitData={splits}/>
            )}
          />
        </div>
      ) : null}
    </Container>
  );
}

export default withOktaAuth(PodSettingsManager);
