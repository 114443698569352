import React, { Component } from 'react';

class SplitCardHeaders extends Component {
   render() {
      return (
         <tr>
            <td ><b style={{ fontStyle: 'bold', fontWeight: 'bold' }}>Monitor on Display Board?</b></td>
            <td><b style={{ fontStyle: 'bold', fontWeight: 'bold' }}>Split Name</b></td>
            <td><b style={{ fontStyle: 'bold', fontWeight: 'bold' }}>Position</b></td>
            <td >
               {/* <table border="0" cellspacing="3" cellpadding="2"> */}
                  <tr>
                     <td colSpan="0" align="center"><b style={{ fontStyle: 'bold', fontWeight: 'bold' }}>Calls Waiting Thresholds</b></td>
                  </tr>
               {/* </table> */}
            </td>
            <td >
               {/* <table border="0" cellspacing="0" cellpadding="2"> */}
                  <tr>
                     <td colspan="0" align="center"><b style={{ fontStyle: 'bold', fontWeight: 'bold' }}>Oldest Call Waiting Thresholds (seconds)</b></td>
                  </tr>
               {/* </table> */}
            </td>
         </tr>
      );
   }
}

export default SplitCardHeaders;
