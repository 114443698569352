let APP_ISSUER,APP_CLIENT_ID,APP_BASE_API_URL,APP_POD_URL;

const current_url = `${window.location.origin + window.location.pathname}`;
if(current_url.includes('localhost')) {
    APP_ISSUER='https://chla.oktapreview.com/oauth2/ausmfj33bf8BQKLCF0h7';
    APP_CLIENT_ID='0oapk0y2dbxWmKR2Z0h7';
    APP_BASE_API_URL='https://api-appdev-trackingboard-test-001.ase-eapps-prod-001.p.azurewebsites.net/api/Phone/';
    APP_POD_URL='https://api-appdev-trackingboard-test-001.ase-eapps-prod-001.p.azurewebsites.net/api/pod';
}
else if(current_url.includes('test-001')) {
    APP_ISSUER='https://chla.oktapreview.com/oauth2/ausmfj33bf8BQKLCF0h7'
    APP_CLIENT_ID='0oapk0y2dbxWmKR2Z0h7'
    APP_BASE_API_URL='https://api-appdev-trackingboard-test-001.ase-eapps-prod-001.p.azurewebsites.net/api/Phone/'
    APP_POD_URL='https://api-appdev-trackingboard-test-001.ase-eapps-prod-001.p.azurewebsites.net/api/pod'
}
else if(current_url.includes('prod-uat')) {
    APP_ISSUER='https://oktalogin.chla.org/oauth2/aus77svjdiCvaf9cC2p7'
    APP_CLIENT_ID='0oa7mj3sbnI8Mb3O42p7'
    APP_BASE_API_URL='https://api-appdev-trackingboard-prod-uat.ase-eapps-prod-001.p.azurewebsites.net/api/Phone/'
    APP_POD_URL='https://api-appdev-trackingboard-prod-uat.ase-eapps-prod-001.p.azurewebsites.net/api/pod'
}
else if(current_url.includes('prod')|| current_url.includes('trackingboard.chla')) {
    APP_ISSUER='https://oktalogin.chla.org/oauth2/aus77svjdiCvaf9cC2p7'
    APP_CLIENT_ID='0oa7mj3sbnI8Mb3O42p7'
    APP_BASE_API_URL='https://api-appdev-trackingboard-prod.ase-eapps-prod-001.p.azurewebsites.net/api/Phone/'
    APP_POD_URL='https://api-appdev-trackingboard-prod.ase-eapps-prod-001.p.azurewebsites.net/api/pod'
}
else {
    APP_ISSUER='https://chla.oktapreview.com/oauth2/ausmfj33bf8BQKLCF0h7'
    APP_CLIENT_ID='0oapk0y2dbxWmKR2Z0h7'
    APP_BASE_API_URL='https://api-appdev-trackingboard-test-001.ase-eapps-prod-001.p.azurewebsites.net/api/Phone/'
    APP_POD_URL='https://api-appdev-trackingboard-test-001.ase-eapps-prod-001.p.azurewebsites.net/api/pod'
}

export default {
    APP_ISSUER,
    APP_CLIENT_ID,
    APP_BASE_API_URL,
    APP_POD_URL,
}