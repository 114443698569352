import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import DisplayBoardSettingsBoardName from "../components/DisplayBoardsComponent/DisplayBoardSettingsBoardName";
import DisplayBoardSettingsButtons from "../components/DisplayBoardsComponent/DisplayBoardSettingsButtons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PodCardHeader from "../components/DisplayBoardsComponent/PodCardHeader";
import { withOktaAuth } from "@okta/okta-react";
import CallApis from "../DataServices/CallApis";
import CircularProgress from "@material-ui/core/CircularProgress";
import AuxCardHeader from "../components/DisplayBoardsComponent/AuxCardHeader";
import DisplayOptionsCheckBox from "../components/DisplayBoardsComponent/DisplayOptionsCheckBox";
import PodSettingsSplit from "../components/DisplayBoardsComponent/PodSettingsSplit";
import DisplayBoardsSettingsAux from "../components/DisplayBoardsComponent/DisplayBoardsSettingsAux";
import ClinicDropDown from "../components/DisplayBoardsComponent/ClinicDropDown";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HighLightWhenComponent from "../components/DisplayBoardsComponent/HighlightWhenComponent";

function MultiClinicBoardSettings(props) {
  const [boardName, setBoardName] = useState("");
  const [pods, setPods] = useState([]);
  const [auxReasonList, setAuxReasonList] = useState([]);
  const [selectedPods, setSelectedPods] = useState([]);
  const [podInfoStore, setPodInfoStore] = useState({});
  const [auxInfoStore, setAuxInfoStore] = useState({});
  const [agentConfig, setAgentConfig] = useState({
    acdAlert: "",
    acdWarnig: "",
    abandon: false,
    loggedout: false
  });
  const [user, setUser] = useState("");
  const [responseMessages, setResponseMesssages] = useState({
    status: "",
    message: ""
  });
  const [loader, setloader] = useState(false);
  const match = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    const setDataForEdit = (rtboardindividual, podList, tempAuxInfo) => {
      let auxreasonsFromState = [];
      let splitInfoFromState = [];
      let splitInfoObj = {};
      let { BoardSetting } = rtboardindividual;
      let {
        acdalerttime,
        acdwarningtime,
        auxreasons,
        showloggedoutagents,
        showservicelevelabandons,
        splits
      } = BoardSetting;
      setAgentConfig({
        acdWarnig: acdwarningtime,
        acdAlert: acdalerttime,
        abandon: showservicelevelabandons === "True" ? true : false,
        loggedout: showloggedoutagents === "True" ? true : false
      });
      auxreasonsFromState = auxreasons;
      splitInfoFromState = splits.map((el) => parseInt(el.key));
      // if opened in edit mode
      if (splitInfoFromState.length !== 0) {
        let podstoedit = podList[1].filter((element) =>
          splitInfoFromState.includes(element.value)
        );
        setSelectedPods(podstoedit);

        for (let split of splits) {
          splitInfoObj[parseInt(split.key)] = {
            oldestcallalert: split.oldestcallwaitingalert,
            oldestcallwarning: split.oldestcallwaitingwarning,
            callalert: split.callswaitingalert,
            callwarning: split.callswaitingwarning,
            position: split.position
          };
        }
        createPodSplits(podstoedit, splitInfoObj);
      }

      // if opened in edit mode
      if (auxreasonsFromState.length !== 0) {
        for (let reason of auxreasonsFromState) {
          if (reason.key !== "99999") {
            tempAuxInfo[reason.key].checkbox = true;
            tempAuxInfo[reason.key].alert = reason.alerttime;
            tempAuxInfo[reason.key].warning = reason.warningtime;
          }
        }
      }
      setAuxInfoStore(tempAuxInfo);
    };

    const prepareData = async () => {
      let tempAuxInfo = {};
      let token = await props.authService.getAccessToken();
      let podRequest = CallApis.prototype.getAllPods(token);
      let auxRequest = CallApis.prototype.createDimAuxReason(token);
      let [podList, auxList] = await Promise.all([podRequest, auxRequest]);
      for (let auxEntry of auxList) {
        tempAuxInfo[auxEntry.auxAltKey] = {
          warning: "",
          alert: "",
          checkbox: false
        };
      }
      setAuxInfoStore(tempAuxInfo);
      if (props.location.state) {
        if (props.location.state.boardId !== null) {
          //get the board settings to initiate data for editing
          let [rtboardIndividual] =
            await CallApis.prototype.createNewRealtimeBoardSettingsIndividual(
              token,
              props.location.state.boardId
            );

          setDataForEdit(rtboardIndividual, podList, tempAuxInfo);
        }
      }

      setPods(podList);
      setAuxReasonList(auxList);
      // making a templete to store aux data
      if (podList.length === 0) {
        setResponseMesssages({
          status: "FAIL",
          message:
            "No Pods in Database, Please create some pods, go to PodSettings Page"
        });
      }
    };

    const getLoggedUser = async () => {
      let user = await props.authService.getUser();
      setUser(user);
    };
    if (props.location.state !== null) {
      setBoardName(props.location.state.boardName);
    }
    getLoggedUser();
    prepareData();
  }, []);

  const handleChange = (event) => {
    if (event !== null && event.length > 6) {
      setResponseMesssages({
        status: "FAIL",
        message: "One Board cannot have more than 6 pods"
      });
      return;
    }
    setSelectedPods(event || []);
    createPodSplits(event || []);
  };

  const displayAuxList = () => {
    return auxReasonList.map((auxReason, index) => {
      const auxInfo = auxInfoStore[auxReason.auxAltKey];
      return (
        <DisplayBoardsSettingsAux
          auxKey={auxReason.auxAltKey}
          auxName={auxReason.description}
          auxWarning={auxInfo ? auxInfo.warning : ""}
          auxAlert={auxInfo ? auxInfo["alert"] : ""}
          auxCheckBox={auxInfo ? auxInfo.checkbox : ""}
          handleAuxChange={(...args) => {
            handleAuxChange(args);
          }}
        />
      );
    });
  };
  const makePostRequest = async (postObject) => {
    const accessToken = await props.authService.getAccessToken();
    CallApis.prototype
      .postNewRealTimeBoardSettings(accessToken, postObject)
      .then((response) => {
        setloader(false);
        setResponseMesssages({
          status: "PASS",
          message: "board created successfully"
        });
        setTimeout(() => {
          window.location.replace(
            window.location.origin + "/MultiClinicBoardLists"
          );
        }, 1100);
      })
      .catch((error) => {
        setloader(false);
       console.log("boardlength"+ postObject.boardName)
       if(postObject.boardName==null)
       {
        setResponseMesssages({
          status: "FAIL",
          message: "Board name is empty"
        });
       }
       else{
        setResponseMesssages({
          status: "FAIL",
          message: "Something went wrong yes"
        });
       }
        console.log(error.response);
      });
  };
  const makePutRequest = async (requestBody, boardId) => {
    const accessToken = props.authState.accessToken;
    requestBody = { ...requestBody };

    CallApis.prototype
      .putNewRealTimeBoard(accessToken, requestBody, boardId)
      .then((response) => {
        setResponseMesssages({
          status: "PASS",
          message: "Board edited successfully"
        });
        setTimeout(() => {
          window.location.replace(
            window.location.origin + "/MultiClinicBoardLists"
          );
        }, 1020);
      })
      .catch((error) => {
        setloader(false);
        setResponseMesssages({
          status: "FAIL",
          message: "Something went wrong"
        });
        console.log(error.response);
      });
  };
  const handleSubmit = () => {
    setloader(true);
    if (boardName === "") {
      setloader(false);
      setResponseMesssages({ status: "FAIL", message: "Board name empty" });
      return;
    }
    let auxreasonsList = [];
    for (let reason in auxInfoStore) {
      if (auxInfoStore[reason].checkbox)
        auxreasonsList.push({
          key: reason,
          warningtime: auxInfoStore[reason].warning,
          alerttime: auxInfoStore[reason].alert
        });
    }
    let podconfigs = [];
    for (let pod in podInfoStore) {
      let { position } = podInfoStore[pod];
      if (position === undefined || position === "") {
        setloader(false);
        setResponseMesssages({
          status: "FAIL",
          message: "Position field is empty for one or more selected pods"
        });
        return;
      }
      podconfigs.push({
        podKey: parseInt(pod),
        position: parseInt(podInfoStore[pod].position),
        callsWaitingWarning: parseInt(podInfoStore[pod].callwarning),
        callsWaitingAlert: parseInt(podInfoStore[pod].callalert),
        oldestcallwaitingwarning: parseInt(podInfoStore[pod].oldestcallwarning),
        oldestcallwaitingalert: parseInt(podInfoStore[pod].oldestcallalert)
      });
    }
    let payload = {
      boardName: boardName,
      createdBy: user.name,
      acdWarningTime: parseInt(agentConfig.acdWarnig),
      acdAlertTime: parseInt(agentConfig.acdAlert),
      showLoggedOutAgents: agentConfig.loggedout,
      showServiceLevelAbandons: agentConfig.abandon,
      auxreasons: JSON.stringify(auxreasonsList),
      boardToPod: podconfigs
    };
    if (props.location.state !== null && props.location.state.type === "put") {
      makePutRequest(payload, props.location.state.boardId);
    } else makePostRequest(payload);
  };
  const handleSplitChange = (stateInfo) => {
    let [status, key, entity] = stateInfo;
    let tempInfoStore = { ...podInfoStore };
    tempInfoStore[key][entity] = status;
    setPodInfoStore(tempInfoStore);
  };
  const handleAuxChange = (stateInfo) => {
    let [status, auxKey, entity] = stateInfo;
    let tempauxInfoStore = { ...auxInfoStore };
    tempauxInfoStore[auxKey][entity] = status;
    setAuxInfoStore(tempauxInfoStore);
  };

  const createPodSplits = (selectedPods, splitInfostate) => {
    let splitInfo = {};
    for (let pod of selectedPods) {
      if (podInfoStore.hasOwnProperty(pod.value)) {
        splitInfo[pod.value] = podInfoStore[pod.value];
      } else {
        splitInfo[pod.value] = {
          oldestcallalert: undefined,
          oldestcallwarning: undefined,
          callalert: undefined,
          callwarning: undefined,
          position: undefined
        };
      }
    }
    if (splitInfostate !== undefined && splitInfostate !== null) {
      for (let split in splitInfostate) {
        if (splitInfo.hasOwnProperty(split)) {
          splitInfo[split] = splitInfostate[split];
        }
      }
    }

    setPodInfoStore(splitInfo);
  };
  const showSplits = () => {
    let splitsJSX = [];
    for (let pod of selectedPods) {
      let podKey = pod.value;
      splitsJSX.push(
        <PodSettingsSplit
          splitName={pod.label}
          handleSplitChange={(...args) => handleSplitChange(args)}
          splitKey={podKey}
          position={podInfoStore[podKey].position || ""}
          oldestcallsWaitingAlert={podInfoStore[podKey].oldestcallalert || ""}
          oldestcallsWaitingWarning={
            podInfoStore[podKey].oldestcallwarning || ""
          }
          callsWaitingAlert={podInfoStore[podKey].callalert || ""}
          callsWaitingWarning={podInfoStore[podKey].callwarning || ""}
        />
      );
    }
    return splitsJSX;
  };
  return (
    <Container fluid className="main-content-container px-4">
      {responseMessages.message !== "" ? (
        <Card
          small
          className="md-3"
          style={{ margin: 20, textAlign: "center" }}
        >
          <CardBody className="d-flex justify-content-between align-items-center">
            <span
              style={{
                fontWeight: "bolder",
                fontFamily: "Poppins",
                textAlign: "center",
                width: "100%",
                color:
                  responseMessages.status !== "FAIL" ? "#004877" : "#ea1d36"
              }}
            >
              {responseMessages.message}
            </span>
            <IconButton
              style={{ float: "right" }}
              onClick={() => setResponseMesssages({ status: "", message: "" })}
              size="small"
            >
              <HighlightOffIcon />
            </IconButton>
          </CardBody>
        </Card>
      ) : null}

      <DisplayBoardSettingsButtons
        title="Multi-Clinic Board Settings"
        saveBtnTitle="Save Board"
        disabled={selectedPods.length === 0 || loader}
        postData={handleSubmit}
        putData={handleSubmit}
        location={props.location}
        goOnCancel="/MultiClinicBoardLists"
      />
      <DisplayBoardSettingsBoardName
        boardName={boardName}
        displayName="Board Name"
        placeholder="Board name..."
        setState={setBoardName}
        valid={props.location.state}
      />
      {loader ? (
        <CircularProgress
          style={{
            marginTop: "25%",
            marginLeft: "50%",
            position: "absolute",
            zIndex: "500"
          }}
          size={60}
        />
      ) : null}
      {pods.length !== 0 ? (
        <Row>
          <Col lg="6" xs="12" md="12" className="mb-4">
            <Card
              small
              className="mb-4"
              style={{
                maxHeight: "100rem",
                height: match ? "85rem" : "25rem"
              }}
            >
              <ClinicDropDown
                acceptsMultiple={true}
                populateDropDown={pods[1]}
                _handleChange={handleChange}
                selectedOption={selectedPods}
                type="post"
                label="Pods"
              />
              <div
                style={{
                  height: "inherit",
                  overflowY: "auto",
                  marginTop: "1rem"
                }}
              >
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style={{ marginLeft: "2%" }}
                >
                  <PodCardHeader />
                  {showSplits()}
                </table>
              </div>
            </Card>
          </Col>
          <Col lg="6" md="12" className="mb-4">
            <Card
              small
              className="mb-4"
              style={{
                paddingBottom: "2px",
                maxHeight: "100rem",
                overFlowY: "auto",
                height: "85rem"
              }}
            >
              <CardHeader className="border-bottom">
                <h6 style={{ fontFamily: "Poppins" }} className="m-0">
                  Agent Monitoring Setup
                </h6>
              </CardHeader>
              <Col md="auto" style={{ height: "inherit", overflowY: "auto" }}>
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style={{ marginLeft: "2%" }}
                >
                  <AuxCardHeader />
                  {displayAuxList()}
                </table>

                <HighLightWhenComponent
                  acdAlert={agentConfig.acdAlert}
                  acdWarnig={agentConfig.acdWarnig}
                  setStateacdAlert={(event) => {
                    setAgentConfig((prevState) => ({
                      ...prevState,
                      acdAlert: event
                    }));
                  }}
                  setStateacdWarning={(event) => {
                    setAgentConfig((prevState) => ({
                      ...prevState,
                      acdWarnig: event
                    }));
                  }}
                />

                <DisplayOptionsCheckBox
                  loggedout={agentConfig.loggedout}
                  abandon={agentConfig.abandon}
                  setStateLoggedout={(event) => {
                    setAgentConfig((prevState) => ({
                      ...prevState,
                      loggedout: event
                    }));
                  }}
                  setStateAbandon={(event) => {
                    setAgentConfig((prevState) => ({
                      ...prevState,
                      abandon: event
                    }));
                  }}
                />
              </Col>
            </Card>
          </Col>
        </Row>
      ) : (
        <CircularProgress style={{ marginLeft: "50%", marginTop: "25%" }} />
      )}
    </Container>
  );
}

export default withOktaAuth(MultiClinicBoardSettings);
