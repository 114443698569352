import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "shards-react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import DisplayBoardsRow from '../components/DisplayBoardsComponent/DisplayBoardsRow'
import { withOktaAuth } from '@okta/okta-react'
import CallApis from '../DataServices/CallApis'
import { checkAuthentication } from "../DataServices/helper";
import DisplayBoardHeader from '../components/DisplayBoardsComponent/DisplayBoardHeader'
import DeleteConfirmDialog from '../components/DisplayBoardsComponent/DeleteConfirmDialog'
import DisplayBoardsCardHeader from '../components/DisplayBoardsComponent/DisplayBoardsCardHeader'
class DisplayBoards extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DisplayBoardSettingData: '',
      redirect: false,
      clinicKey: '',
      boardName: '',
      boardId: '',
      AccessToken: '',
      open: false,
      nodata: false,
      errorMessage: '',
      redirectAlert: false,
      dataGot: false,
      showMessage: '',
      authenticated: null, userInfo: null,
      timeout:60,
      flag:0,
      destination:'board'
    };
    this.checkAuthentication = checkAuthentication.bind(this);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
  }
  async login() {
    this.props.authService.login("/");
  }

  async logout() {
    // this.props.authService.logout("/");
  }
  //opens the new window for indivisual display board
  openDialog = (boardId) => {
    window.open(`./displayboard/?id=${boardId}`, "data", 'height=800,width=3600,resizable=1');
  }
  //calls the Display board settings API
  async getBoardNames() {
    const AccessToken = await this.props.authService.getAccessToken();
    if (this.state.errorMessage !== '') {
      return
    }
    CallApis.prototype.createRealtimeBoardSettings(AccessToken).then((response) => {
      if (response.message === 'Request failed with status code 404') {
        this.setState({ nodata: true, DisplayBoardSettingData: [] })
        return
      }
      if (response !== undefined) {

        this.setState({ DisplayBoardSettingData: response, dataGot: true })
      }
    }).catch((err) => {
      console.log(err)
      if(this.state.timeout===60)
            {
                setInterval(async ()=>{ 
               let tim=this.state.timeout
               if(tim!==0)
           this.setState({
               timeout:tim-3,
               flag:1
            })
            }, 3000);
            }
      this.setState({ loader: false, redirectAlert: true, errorMessage: err })
    })
  }

  reconnect(){
       

    setTimeout(function(){ 
        
        window.location.reload(false); }, 60000);
return null
}
  //sets the type (PUT / POST) while moving to display board settings
  onsubmit = (clinicKey, boardName, boardId, type,dest) => {
    this.setState({
      redirect: true,
      clinicKey,
      boardName,
      boardId,
      type: type,
      destination:dest
    })
  }
  createboardnames() {
    if (this.state.DisplayBoardSettingData === '' && this.state.dataGot !== true) {
      this.getBoardNames()
      return
    }

    let arr = []
    let obj = this.state.DisplayBoardSettingData
    if (obj.length === 0 && !this.state.nodata) {
      this.setState({ nodata: true })
      return null
    }

    if (this.state.DisplayBoardSettingData !== '') {
      obj.sort(function (a, b) {
        var x = a.BoardName.toLowerCase();
        var y = b.BoardName.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
    for (let i = 0; i < obj.length; i++) {
      const {
        BoardName = "",
        CreatedByUsername = "",
        BoardSetting,
        RealTimeBoardId = "0",
      } = obj[i];
      arr.push(
        <DisplayBoardsRow BoardName={BoardName}
          CreatedByUsername={CreatedByUsername}
          RealTimeBoardId={RealTimeBoardId}
          onDelete={(status, id, name, ) => this.setState({ open: status, boardId: id, boardName: name })}
          onSettings={(key, name, id, type,dest) => { this.onsubmit(key, name, id, type,dest) }}
          onDisplayBoard={(id) => { this.openDialog(id) }}
          ClinicKey={BoardSetting.clinickey}
          key={BoardName+i}
        />
      )
    }
    return arr
  }
  renderRedirect = () => {
    if(this.state.redirect){
      if(this.state.destination==='pod')
        return <Redirect to={{ pathname: '/MultiClinicBoardSettings', state: { clinicKey: this.state.clinicKey, boardName: this.state.boardName, boardId: this.state.boardId, type: this.state.type } }} />
      else if(this.state.destination==='board')
        return <Redirect to={{ pathname: '/DisplayBoardSettings', state: { clinicKey: this.state.clinicKey, boardName: this.state.boardName, boardId: this.state.boardId, type: this.state.type } }} />
    }
  }
  handleClose() {
    if (this.state.open) {
      this.setState({ open: false })
    }
    else {
      this.setState({ open: true })
    }
  }
  async Agree() {
    const AccessToken = await this.props.authService.getAccessToken();
    CallApis.prototype.delete(AccessToken, this.state.boardId).then((response) => {
      
      this.setState({ open: false, showMessage: 'Displayboard deleted successfully' })
      this.getBoardNames()
      window.scrollTo(0,0);
    }).catch((err) => {
      this.setState({ open: false, loader: false, redirectAlert: true, showMessage: err.response.data + " " + err.message })
    })
  }
  handleAlertClose() {
    if (this.state.redirectAlert) {
      this.setState({ redirectAlert: false })
    }
    else {
      this.setState({ redirectAlert: true })
    }
  }
  componentDidMount(){
    this.checkAuthentication();
  }
  componentDidUpdate(){
    //this.checkAuthentication();
  }
  render() {
    if (this.state.authenticated === null) return  <Container fluid className="main-content-container px-4"></Container>;
    
    
    if (this.state.authenticated) {

      if(this.state.errorMessage!=='')
      {
          
          return(
            <Container fluid className="main-content-container px-4">
             <Row>
          <Col lg="6">
              {this.reconnect()}
              <Card small className="md-3" style={{ margin: 20, textAlign: "center" }}><CardBody className="p-0 pb-2"><span style={{ fontWeight: "bolder", textAlign: 'center', color: '#ff4d4d' }}>{this.state.errorMessage.response !== undefined ? this.state.errorMessage.response.data + " " + this.state.errorMessage.message : this.state.errorMessage.message +' '+`Server disconnected, trying to reconnect in ${this.state.timeout} sec …`}</span></CardBody></Card>
              {/* <span style={{ fontSize: 30, color: 'red' }}>{this.state.errorMessage.message+' '+`Server disconnected, trying to reconnect in ${this.state.timeout} sec …`}</span>  */}
              </Col>
              </Row>
              </Container>
          )
      }
    return (
      <Container fluid className="main-content-container px-4">
        {this.renderRedirect()}
        <Row>
          <Col lg="6" md='8'>
            {this.state.showMessage !== '' ? <Card small className="md-3" style={{ margin: 20, textAlign: "center" }}><CardBody className="p-0 pb-2"><span style={{ fontWeight: "bolder", textAlign: 'center', color: '#007acc' }}>{this.state.showMessage}</span></CardBody></Card> : null}
            {this.state.errorMessage !== '' ? <Card small className="md-3" style={{ margin: 20, textAlign: "center" }}><CardBody className="p-0 pb-2"><span style={{ fontWeight: "bolder", textAlign: 'center', color: '#ff4d4d' }}>{this.state.errorMessage.response !== undefined ? this.state.errorMessage.response.data + " " + this.state.errorMessage.message : this.state.errorMessage.message}</span></CardBody></Card> : null}
            <Card small className="mb-3">
              <DisplayBoardsCardHeader 
              headertitle={'Display Boards'}
              gotoBoardHandler={() => { this.onsubmit(null, null, null, 'post','board') }} 
              gotoPodHandler={()=>{this.onsubmit(null,null,null,'post','pod')}} />
              <CardBody className="p-0 pb-2" >
                <table className="table mb-0" style={{ backgroundColor: 'white', paddingBottom: '100%', marginBottom: '100%' }}>
                  <DisplayBoardHeader />
                  <tbody>
                    {this.createboardnames()}
                    {this.state.nodata ? <tr><td colSpan='75%' style={{
                      textAlign: "center",
                      padding: "20%",
                      color: 'grey',
                      fontSize: 20
                    }}>No data to display</td></tr> : null}
                  </tbody>
                </table>
              </CardBody>
            </Card>
           

            {this.state.DisplayBoardSettingData === '' && this.state.dataGot !== true && !this.state.nodata ? this.state.errorMessage !== '' ? null : <ThemeProvider theme={theme}><CircularProgress style={{ margin: '50%' }} /></ThemeProvider> : null}
          </Col>
          {/* <Col>
          <Card small className="mb-3">
          <CardBody className="p-0 pb-2" >
          <CardHeader className="border-bottom">
        <Row>
          <Col>
            <h5 className="m-0">Chat App</h5>
          </Col>
        </Row>
      </CardHeader>
          <iframe src='https://webchat.botframework.com/embed/servicedeskwebchatbot?s=bgu2C3uVLbM.O21nyj7371wM7IZ_CsPXkaFjUR_Ul3sgY86rfgRyB6Q&username=piyush'  style={{minWidth: 400, width: "100%", minHeight: 500}}></iframe>
          </CardBody>
          </Card>
          </Col> */}
        </Row>
        <DeleteConfirmDialog open={this.state.open} handleClose={() => { this.handleClose() }} Agree={() => { this.Agree() }} boardName={this.state.boardName} />
      </Container>
    );
    }
  }
}
const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiCircularProgress: {
      // Name of the rule
      colorPrimary: {
        // Some CSS
        color: 'grey',
        opacity: 0.5
      },
    },
  },
});

export default withOktaAuth(DisplayBoards);