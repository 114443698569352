import React, { Component } from 'react';

class DisplayBoardHeader extends Component {
  render() {
    return (
      <thead className="bg-dark">
        <tr style={{ paddingBottom: '63%' }}>
          <th scope="col" className="border-0">
            <b style={{ color: 'black', fontType: 'bold', fontSize: '20px',fontFamily:'Poppins' }}>Board Name</b>
          </th>
          <th scope="col" className="border-0">
            <b style={{ color: 'black', fontType: 'bold', fontSize: '20px' ,fontFamily:'Poppins'}}>Created By</b>
          </th>
          <th scope="col" className="border-0">
            <b style={{ color: 'black', fontType: 'bold', fontSize: '20px' ,fontFamily:'Poppins'}}>Options</b>
          </th>
        </tr>
      </thead>
    );
  }
}

export default DisplayBoardHeader;
