import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  }

  render() {
    const { hideLogoText } = this.props;
    return (
      <div className="main-navbar" style={{ backgroundColor: "#004877" }}>
        <Navbar className="align-items-stretch flex-md-nowrap border-bottom p-0">
          <NavbarBrand
            className="w-100 mr-0"
            href="#"
            style={{ lineHeight: "25px", backgroundColor: "#004877" }}
          >
            <div className="d-flex ml-auto mr-auto" >
              <img
                id="main-logo"
                // className="d-inline-block align-top mr-2"
                style={{
                  maxWidth: "2.8em",
                  maxHeight: "2.8em",
                  marginLeft: "-1em",
                  marginTop:"0.2em",
                  marginBottom:"0.2em"
                }}
                src={require("../../../assets/images/chlalogo.png")}
                alt="Referrals Management Dashboard"
              />
              {!hideLogoText && (
                <span
                  className="d-none d-md-inline ml-1"
                  style={{
                    color: "white",
                    backgroundColor: "#004877",
                    fontFamily: "Poppins",
                    fontSize: 12,
                    marginTop: "0.7em",
                    paddingLeft:"0.4em"
                  }}
                >
                   Display Board Management
                </span>
              )}
            </div>
          </NavbarBrand>
          {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons" style={{ color: "#ffffff" }}>
              &#xE5C4;
            </i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
