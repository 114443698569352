import React, { Component } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { withOktaAuth } from "@okta/okta-react";
import CallApis from "../DataServices/CallApis";
import DisplayBoardSettingsButtons from "../components/DisplayBoardsComponent/DisplayBoardSettingsButtons";
import DisplayBoardSettingsBoardName from "../components/DisplayBoardsComponent/DisplayBoardSettingsBoardName";
import SplitCardHeaders from "../components/DisplayBoardsComponent/SplitCardHeaders";
import ClinicDropDown from "../components/DisplayBoardsComponent/ClinicDropDown";
import DisplayBoardsSettingsSplit from "../components/DisplayBoardsComponent/DisplayBoardsSettingsSplit";
import DisplayBoardsSettingsAux from "../components/DisplayBoardsComponent/DisplayBoardsSettingsAux";
import AuxCardHeader from "../components/DisplayBoardsComponent/AuxCardHeader";
import DisplayOptionsCheckBox from "../components/DisplayBoardsComponent/DisplayOptionsCheckBox";
import HighLightWhenComponent from "../components/DisplayBoardsComponent/HighlightWhenComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
class DisplayBoardSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinicKey: "",
      boardName: "",
      boardId: "",
      type: "",
      DimSplit: "",
      DimClinic: "",
      DimAuxReason: "",
      selectedOption: "",
      populateDropDown: "",
      RealtimeBoardSettings: [],
      RealtimeBoardSettingsIndivisual: "",
      position: [],
      splitName: [],
      callsWaitingWarning: [],
      callsWaitingAlert: [],
      oldestcallsWaitingWarning: [],
      oldestcallsWaitingAlert: [],
      splitcheckbox: {},
      auxCheckBox: {},
      auxWarning: [],
      auxAlert: [],
      splitkeys: [],
      auxkeys: [],
      abandon: false,
      loggedout: false,
      acdAlert: "",
      acdWarnig: "",
      redirectAlert: false,
      open: false,
      counter: 0,
      name: "",
      loader: false,
      errorMessage: "",
      showMessage: "",
      positionValidation:false
    };
  }
  _handleChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption,
      clinicKey: selectedOption.value,
      position: [],
      splitkeys: [],
      callsWaitingWarning: [],
      callsWaitingAlert: [],
      oldestcallsWaitingWarning: [],
      oldestcallsWaitingAlert: [],
      splitcheckbox: [],
    });
  };
  // calls all the APIs
  async callApis() {
    if (this.state.errorMessage !== "") {
      return;
    }
    const CallApi = CallApis.prototype;
    const AccessToken = await this.props.authService.getAccessToken();
    const userinfo = await this.props.authService.getUser();
    if (this.state.DimSplit === "")
      CallApi.createDimSplit(AccessToken)
        .then((response) => {
          this.setState({ DimSplit: response, name: userinfo.name });
        })
        .catch((err) => {
          this.setState({ loader: false, errorMessage: err });
        });
    if (this.state.DimClinic === "")
      CallApi.createDimClinic(AccessToken)
        .then((response) => {
          let res = [...response[1]];
          res.sort(function (a, b) {
            var x = a.label.toLowerCase();
            var y = b.label.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          if (this.state.clinicKey === "") this._handleChange(res[0]);
          this.setState({ DimClinic: response[0], populateDropDown: res });
        })
        .catch((err) => {
          this.setState({ loader: false, errorMessage: err });
        });
    if (this.state.DimAuxReason === "")
      CallApi.createDimAuxReason(AccessToken)
        .then((response) => {
          this.setState({ DimAuxReason: response });
        })
        .catch((err) => {
          this.setState({ loader: false, errorMessage: err });
        });
    if (this.state.RealtimeBoardSettingsIndivisual === "")
      if (this.props.location.state !== null) {
        if (this.props.location.state.type !== "post")
          CallApi.createRealtimeBoardSettingsIndivisual(
            AccessToken,
            this.props.location.state.boardId
          )
            .then((response) => {
              if (this.state.counter === 0) {
                const {
                  acdwarningtime = "",
                  acdalerttime = "",
                  showloggedoutagents = "",
                  showservicelevelabandons = "",
                } = response[0].BoardSetting;
                let acdWarnig = acdwarningtime;
                let acdAlerttime = acdalerttime;
                let showLoggedoutagents =
                  showloggedoutagents === "true" ? true : false;
                let showServicelevelabandons =
                  showservicelevelabandons === "true" ? true : false;
                this.setState({
                  RealtimeBoardSettingsIndivisual: response,
                  acdAlert: acdAlerttime,
                  acdWarnig: acdWarnig,
                  loggedout: showLoggedoutagents,
                  abandon: showServicelevelabandons,
                  counter: 1,
                });
              }
              this.setState({ RealtimeBoardSettingsIndivisual: response });
            })
            .catch((err) => {
              this.setState({ loader: false, errorMessage: err });
            });
      }
  }
  // Handles the state variable when any value is changed in any split
  handleSplitChange(status, splitKey, where) {
    let splitkeys = this.state.splitkeys;
    let position = this.state.position;
    let callsWaitingAlert = this.state.callsWaitingAlert;
    let callsWaitingWarning = this.state.callsWaitingWarning;
    let oldestcallalert = this.state.oldestcallsWaitingAlert;
    let oldestcallwarning = this.state.oldestcallsWaitingWarning;
    if (where === "checkbox") {
      let checkbox = this.state.splitcheckbox;
      checkbox[splitKey] = status;
      if (status) {
        splitkeys.push("" + splitKey);
      } else {
        splitkeys.splice(splitkeys.indexOf("" + splitKey), 1);
      }
      this.setState({ splitcheckbox: checkbox, splitKeys: splitkeys });
    } else if (where === "position") {
      position[splitKey] = status;
      this.setState({ position: position });
    } else if (where === "callwarning") {
      callsWaitingWarning[splitKey] = status;
      this.setState({ callsWaitingWarning: callsWaitingWarning });
    } else if (where === "callalert") {
      callsWaitingAlert[splitKey] = status;
      this.setState({ callsWaitingAlert: callsWaitingAlert });
    } else if (where === "oldestcallalert") {
      oldestcallalert[splitKey] = status;
      this.setState({ oldestcallalert: oldestcallalert });
    } else if (where === "oldestcallwarning") {
      oldestcallwarning[splitKey] = status;
      this.setState({ oldestcallwarning: oldestcallwarning });
    }
  }
  // Handles the state variable when any value is changed in any Aux reasons
  handleAuxChange(status, auxKey, where) {
    let auxKeys = this.state.auxkeys;
    let alert = this.state.auxAlert;
    let auxWarning = this.state.auxWarning;
    if (where === "checkbox") {
      let checkbox = this.state.auxCheckBox;
      checkbox[auxKey] = status;
      if (status) {
        auxKeys.push("" + auxKey);
      } else {
        auxKeys.splice(auxKeys.indexOf(auxKey.toString()), 1);
      }
      this.setState({ auxCheckBox: checkbox, auxkeys: auxKeys });
    } else if (where === "alert") {
      alert[auxKey] = status;
      this.setState({ auxAlert: alert });
    } else if (where === "warning") {
      auxWarning[auxKey] = status;
      this.setState({ auxWarning: auxWarning });
    }
  }
  // Fills the data in the splits from the API
  filltheSplits() {
    if (this.state.RealtimeBoardSettingsIndivisual[0] === undefined) {
      this.callApis();
      return;
    }
    const { splits = [] } =
      this.state.RealtimeBoardSettingsIndivisual[0].BoardSetting;
    let Splits = splits;
    let pos = {};
    let checkbox = {};
    let callsWaitingAlert = {};
    let callsWaitingWarning = {};
    let oldestcallsWaitingAlert = {};
    let oldestcallsWaitingWarning = {};
    let splitkeys = [];
    for (let i = 0; i < Splits.length; i++) {
      const {
        key = "",
        position = "",
        callswaitingwarning = "",
        callswaitingalert = "",
        oldestcallwaitingalert = "",
        oldestcallwaitingwarning = "",
      } = Splits[i];
      splitkeys.push(key);
      pos[key] = position;
      checkbox[key] = true;
      callsWaitingAlert[key] = callswaitingalert;
      callsWaitingWarning[key] = callswaitingwarning;
      oldestcallsWaitingAlert[key] = oldestcallwaitingalert;
      oldestcallsWaitingWarning[key] = oldestcallwaitingwarning;
    }
    this.setState({
      position: pos,
      splitkeys: splitkeys,
      callsWaitingWarning: callsWaitingWarning,
      callsWaitingAlert: callsWaitingAlert,
      oldestcallsWaitingWarning: oldestcallsWaitingWarning,
      oldestcallsWaitingAlert: oldestcallsWaitingAlert,
      splitcheckbox: checkbox,
    });
  }
  // Fills the data in the Aux Reasons from the API
  filltheAuxs() {
    if (this.state.RealtimeBoardSettingsIndivisual[0] === undefined) {
      this.callApis();
      return;
    }
    const { auxreasons = [] } =
      this.state.RealtimeBoardSettingsIndivisual[0].BoardSetting;
    let Auxreasons = auxreasons;
    let auxCheckbox = {};
    let warning = {};
    let alert = {};
    let auxkeys = [];
    for (let i = 0; i < Auxreasons.length; i++) {
      const { key = "", warningtime = "", alerttime = "" } = Auxreasons[i];
      auxkeys.push(key);
      auxCheckbox[key] = true;
      warning[key] = warningtime;
      alert[key] = alerttime;
    }
    if (auxreasons.length === 0) {
      auxkeys.push("9999");
      auxCheckbox["9999"] = true;
      warning["9999"] = "0";
      alert["9999"] = "0";
    }
    this.setState({
      auxCheckBox: auxCheckbox,
      auxWarning: warning,
      auxAlert: alert,
      auxkeys: auxkeys,
    });
  }
  //Checks is the Object is Empty or not
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  // Creates the UI for the AUX reasons
  createAuxs() {
    let DimAux = this.state.DimAuxReason;
    let arr = [];
    if (this.state.RealtimeBoardSettingsIndivisual !== undefined) {
      let RealtimeBoardSettingsIndivisual =
        this.state.RealtimeBoardSettingsIndivisual[0];
      if (
        RealtimeBoardSettingsIndivisual !== undefined &&
        this.isEmpty(this.state.auxCheckBox)
      ) {
        if (this.props.location.state.type !== "post")
          this.filltheAuxs(RealtimeBoardSettingsIndivisual);
      }
      let auxCheckBox = this.state.auxCheckBox;
      for (let i = 0; i < DimAux.length; i++) {
        arr.push(
          <DisplayBoardsSettingsAux
            auxKey={DimAux[i].auxAltKey}
            auxWarning={this.state.auxWarning[DimAux[i].auxAltKey]}
            auxAlert={this.state.auxAlert[DimAux[i].auxAltKey]}
            auxName={this.state.DimAuxReason[i]["description"]}
            auxCheckBox={auxCheckBox[DimAux[i].auxAltKey]}
            handleAuxChange={(element, key, what) => {
              this.handleAuxChange(element, key, what);
            }}
          />
        );
      }
      return arr;
    }
  }
  // Creates the UI for the Splits based on the clinic selected
  createSplits() {
    let DimSplit = this.state.DimSplit;
    if (this.state.RealtimeBoardSettingsIndivisual !== undefined) {
      let RealtimeBoardSettingsIndivisual =
        this.state.RealtimeBoardSettingsIndivisual[0];
      if (
        RealtimeBoardSettingsIndivisual !== undefined &&
        this.state.position === "" &&
        this.props.location.state !== null
      ) {
        if (this.props.location.state.type !== "post") this.filltheSplits();
      }
      let arr = [];
      for (let i = 0; i < DimSplit.length; i++) {
        if (
          parseInt(DimSplit[i].clinicKey) === parseInt(this.state.clinicKey)
        ) {
          arr.push(
            <DisplayBoardsSettingsSplit
              splitName={DimSplit[i].splitName}
              splitKey={DimSplit[i].splitKey}
              splitcheckbox={this.state.splitcheckbox[DimSplit[i].splitKey]}
              position={this.state.position[DimSplit[i].splitKey]}
              callsWaitingAlert={
                this.state.callsWaitingAlert[DimSplit[i].splitKey]
              }
              callsWaitingWarning={
                this.state.callsWaitingWarning[DimSplit[i].splitKey]
              }
              oldestcallsWaitingAlert={
                this.state.oldestcallsWaitingAlert[DimSplit[i].splitKey]
              }
              oldestcallsWaitingWarning={
                this.state.oldestcallsWaitingWarning[DimSplit[i].splitKey]
              }
              handleSplitChange={(what, key, where) => {
                this.handleSplitChange(what, key, where);
              }}
            />
          );
        }
      }
      return arr;
    }
  }
  // Creates the Request Body and calls the PUT request API
  async putData() {
    let boardsettings = this.state.RealtimeBoardSettingsIndivisual[0];
    let splits = [];
    let auxs = [];

    for (let i = 0; i < this.state.splitkeys.length; i++) {
      if (!this.state.position[this.state.splitkeys[i]]) {
        this.setState({ loader: false });
        this.setState({ errorMessage: { message: "Position field is empty for one or more selected splits" } });
        return;
      }
      if((this.state.position[this.state.splitkeys[i]]<0))
      {
        this.setState({ loader: false });
        this.setState({ errorMessage: { message: "Position field not accepted, Negative values" } });
        return;
      }
      let obj = {
        "@key": this.state.splitkeys[i] || "",
        "@position": this.state.position[this.state.splitkeys[i]] || "",
        "@callswaitingwarning":
          this.state.callsWaitingWarning[this.state.splitkeys[i]] || "",
        "@callswaitingalert":
          this.state.callsWaitingAlert[this.state.splitkeys[i]] || "",
        "@oldestcallwaitingwarning":
          this.state.oldestcallsWaitingWarning[this.state.splitkeys[i]] || "",
        "@oldestcallwaitingalert":
          this.state.oldestcallsWaitingAlert[this.state.splitkeys[i]] || "",
      };
      splits.push(obj);
    }
    for (let i = 0; i < this.state.auxkeys.length; i++) {
      let obj = {
        "@key": this.state.auxkeys[i] || "",
        "@warningtime": this.state.auxWarning[this.state.auxkeys[i]] || "",
        "@alerttime": this.state.auxAlert[this.state.auxkeys[i]] || "",
      };
      auxs.push(obj);
    }
    if (auxs.length === 0) {
      let obj = {
        "@key": -1,
        "@warningtime": 120,
        "@alerttime": 150,
      };
      auxs.push(obj);
    }
    if (splits.length === 0) {
      let obj = {
        "@key": 99999,
        "@position": 1,
        "@callswaitingwarning": 1,
        "@callswaitingalert": 1,
        "@oldestcallwaitingwarning": 1,
        "@oldestcallwaitingalert": 1,
      };
      splits.push(obj);
    }
    boardsettings.BoardSettings = {};
    boardsettings.BoardSettings = {
      "q1:RealTimeDisplayBoardSettings": {
        "@xmlns:q1": "settings",
        "@clinickey": this.state.clinicKey,
        "@acdwarningtime": this.state.acdWarnig,
        "@acdalerttime": this.state.acdAlert,
        "@showloggedoutagents": this.state.loggedout,
        "@showservicelevelabandons": this.state.abandon,
        splits: {
          split: splits,
        },
        auxreasons: {
          auxreason: auxs,
        },
      },
    };
    let str = boardsettings.BoardSettings;
    str = JSON.stringify(str);
    boardsettings.BoardSettings = str;
    boardsettings.RealTimeBoardId = parseInt(boardsettings.RealTimeBoardId, 10);
    boardsettings["ClinicKey"] = parseInt(this.state.clinicKey, 10);
    const AccessToken = await this.props.authService.getAccessToken();
    CallApis.prototype
      .putSplits(AccessToken, boardsettings, this.state.boardId)
      .then((response) => {
        this.setState({
          redirectAlert: true,
          loader: false,
          showMessage: "Displayboard updated successfully",
          errorMessage:"",
          clinicKey: "",
          boardName: "",
        });
        window.location.replace(window.location.origin+"/DisplayBoards");
      })
      .catch((err) => {
        this.setState({
          loader: false,
          redirectAlert: true,
          errorMessage: err,
          showMessage: "Displayboard Failed to Update",
          clinicKey: "",
          boardName: "",
        });
        this.callApis();
      });
  }
  // Creates the Request Body and calls the POST request API

  async postData() {
    let splits = [];
    let auxs = [];
    let boardsettings = {};
    boardsettings["q1:RealTimeDisplayBoardSettings"] = {
      "@xmlns:q1": "settings",
      "@clinickey": this.state.clinicKey,
      "@acdwarningtime": this.state.acdWarnig,
      "@acdalerttime": this.state.acdAlert,
      "@showloggedoutagents": this.state.loggedout,
      "@showservicelevelabandons": this.state.abandon,
    };
    for (let i = 0; i < this.state.splitkeys.length; i++) {
      if (!this.state.position[this.state.splitkeys[i]]) {
        this.setState({ loader: false });
        this.setState({ errorMessage: { message: "Position field is empty for one or more selected splits" } });
        return;
      }
      if((this.state.position[this.state.splitkeys[i]]<0))
      {
        this.setState({ loader: false });
        this.setState({ errorMessage: { message: "Position field not accepted, Negative values" } });
        return;
      }
      else{
        this.setState({ errorMessage: { message: null } });
      }
      let obj = {
        "@key": this.state.splitkeys[i] || "",
        "@position": this.state.position[this.state.splitkeys[i]] || "",
        "@callswaitingwarning":
          this.state.callsWaitingWarning[this.state.splitkeys[i]] || "",
        "@callswaitingalert":
          this.state.callsWaitingAlert[this.state.splitkeys[i]] || "",
        "@oldestcallwaitingwarning":
          this.state.oldestcallsWaitingWarning[this.state.splitkeys[i]] || "",
        "@oldestcallwaitingalert":
          this.state.oldestcallsWaitingAlert[this.state.splitkeys[i]] || "",
      };
      splits.push(obj);
    }
    for (let i = 0; i < this.state.auxkeys.length; i++) {
      if (this.state.auxCheckBox[this.state.auxkeys[i]]) {
        let obj = {
          "@key": this.state.auxkeys[i] || "",
          "@warningtime": this.state.auxWarning[this.state.auxkeys[i]] || "",
          "@alerttime": this.state.auxAlert[this.state.auxkeys[i]] || "",
        };
        auxs.push(obj);
      }
    }
    if (auxs.length === 0) {
      let obj = {
        "@key": 99999,
        "@warningtime": 120,
        "@alerttime": 150,
      };
      auxs.push(obj);
    }
    if (splits.length === 0) {
      let obj = {
        "@key": 99999,
        "@position": 1,
        "@callswaitingwarning": 1,
        "@callswaitingalert": 1,
        "@oldestcallwaitingwarning": 1,
        "@oldestcallwaitingalert": 1,
      };
      splits.push(obj);
    }
    boardsettings["q1:RealTimeDisplayBoardSettings"]["splits"] = {
      split: splits,
    };
    boardsettings["q1:RealTimeDisplayBoardSettings"]["auxreasons"] = {
      auxreason: auxs,
    };
    boardsettings = JSON.stringify(boardsettings);
    let postObj = {
      BoardName: this.state.boardName,
      CreatedByUsername: "" + this.state.name,
      ClinicKey: this.state.clinicKey,
      BoardSettings: boardsettings,
    };
    const AccessToken = await this.props.authService.getAccessToken();
    CallApis.prototype
      .postSplits(AccessToken, postObj)
      .then((response) => {
        this.setState({
          redirectAlert: true,
          loader: false,
          showMessage: "Displayboard created successfully",
          clinicKey: "",
          boardName: "",
          position: "",
          splitkeys: "",
          callsWaitingWarning: "",
          callsWaitingAlert: "",
          oldestcallsWaitingWarning: "",
          oldestcallsWaitingAlert: "",
          splitcheckbox: "",
          auxAlert: "",
          auxWarning: "",
          auxCheckBox: {},
          auxKeys: "",
          abandon: false,
          loggedout: false,
          acdAlert: "",
          acdWarnig: "",
        });
        this.callApis();
        window.location.replace(window.location.origin + "/DisplayBoards");
      })
      .catch((err) => {
        this.setState({
          loader: false,
          redirectAlert: true,
          errorMessage: err,
          showMessage: "Displayboard Failed to create",
          clinicKey: "",
          boardName: "",
          position: "",
          splitkeys: "",
          callsWaitingWarning: "",
          callsWaitingAlert: "",
          oldestcallsWaitingWarning: "",
          oldestcallsWaitingAlert: "",
          splitcheckbox: "",
          auxAlert: "",
          auxWarning: "",
          auxCheckBox: {},
          auxKeys: "",
          abandon: false,
          loggedout: false,
          acdAlert: "",
          acdWarnig: "",
        });
        this.callApis();
      });
  }
  handleClose() {
    if (this.state.redirectAlert) {
      this.setState({ redirectAlert: false });
      window.location.reload();
    } else {
      this.setState({ redirectAlert: true });
    }
  }
  render() {
    //checks if the controller is coming from DisplayBoards or directly to the display Board settings
    if (this.props.location.state !== null && this.state.boardName == "") {
      //checks if the controller is coming from indivisual DisplayBoards or via create new board to the display Board settings
      if (this.props.location.state.clinicKey !== null) {
        this.setState({
          clinicKey: this.props.location.state.clinicKey,
          boardName: this.props.location.state.boardName,
          boardId: this.props.location.state.boardId,
          type: this.props.location.state.type,
        });
      }
    }
    if (this.state.DimClinic == "") this.callApis();
    //selects the clinic from dropdown based on the boardId that is coming from DisplayBoards
    if (this.state.selectedOption == "" && this.state.boardId !== "") {
      let dimClinic = this.state.DimClinic;
      let clinicKey = this.state.clinicKey;
      for (let i = 0; i < dimClinic.length; i++) {
        if (clinicKey == dimClinic[i].clinicKey) {
          this._handleChange({
            value: dimClinic[i].clinicKey,
            label: dimClinic[i].clinicName,
          });
          break;
        }
      }
    }
    const progress = (
      <tr>
        <td colSpan="75%" style={{ textAlign: "center" }}>
          <ThemeProvider theme={theme}>
            <CircularProgress style={{ marginTop: 100 }} size={50} />
          </ThemeProvider>
        </td>
      </tr>
    );
    return (
      <Container fluid className="main-content-container px-4">
        {this.state.showMessage !== "" ? (
          <Card
            small
            className="md-3"
            style={{ margin: 20, textAlign: "center" }}
          >
            <CardBody className="p-0 pb-2">
              <span
                style={{
                  fontWeight: "bolder",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  color: "#004877",
                }}
              >
                {this.state.showMessage}
              </span>
            </CardBody>
          </Card>
        ) : null}
        {this.state.errorMessage !== "" ? (
          <Card
            small
            className="md-3"
            style={{ margin: 20, textAlign: "center" }}
          >
            <CardBody className="p-0 pb-2">
              <span
                style={{
                  fontWeight: "bolder",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  color: "#ea1d36",
                }}
              >
                {this.state.errorMessage.response !== undefined
                  ? this.state.errorMessage.response.data +
                    " " +
                    this.state.errorMessage.message
                  : this.state.errorMessage.message}
              </span>
            </CardBody>
          </Card>
        ) : null}
        ̥
        <DisplayBoardSettingsButtons
          title="Display Board Settings"
          saveBtnTitle="Save Board"
          disabled={
            this.state.clinicKey == "" ||
            this.state.boardName == "" ||
            this.state.loader
              ? true
              : false
          }
          location={this.props.location}
          putData={() => {
            this.putData();
            // this.setState({ loader: true });
          }}
          postData={() => {
            this.postData();
            // this.setState({ loader: true });
          }}
          goOnCancel="/DisplayBoards"
        />
        <DisplayBoardSettingsBoardName
          valid={this.props.location.state}
          boardName={this.state.boardName}
          placeholder="Board name..."
          displayName="Board name"
          setState={(value) => {
            this.setState({ boardName: value });
          }}
        />
        <Row>
          <Col lg="6" xs="12" md="12" className="mb-4">
            <Card
              small
              className="mb-4"
              style={{
                maxHeight: "100rem",
                height:
                  window.innerWidth >= theme.breakpoints.width("md")
                    ? "85rem"
                    : "25rem",
              }}
            >
              <ClinicDropDown
                type={this.state.type}
                selectedOption={this.state.selectedOption}
                _handleChange={(obj) => {
                  this._handleChange(obj);
                }}
                populateDropDown={this.state.populateDropDown}
                acceptsMultiple={false}
              />
              <div style={{ overflowY: "auto", height: "inherit" }}>
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style={{ marginLeft: "2%" }}
                >
                  <SplitCardHeaders />
                  {/* creates the Splits UI as well as fills the splits with the data */}
                  {this.state.clinicKey == ""
                    ? this.createAuxs().length === 0
                      ? this.state.errorMessage !== ""
                        ? null
                        : progress
                      : progress
                    : this.createSplits().length === 0
                    ? this.state.errorMessage !== ""
                      ? null
                      : progress
                    : null}
                  {this.isEmpty(this.state.splitcheckbox) &&
                  this.props.location.state !== null
                    ? this.props.location.state.type !== "post"
                      ? this.filltheSplits()
                      : this.createSplits()
                    : this.createSplits()}
                </table>
              </div>
            </Card>
          </Col>
          <Col lg="6" md="12" className="mb-4">
            <Card
              small
              className="mb-4"
              style={{
                paddingBottom: "2px",
                maxHeight: "100rem",
                overFlowY: "auto",
                height: "85rem",
              }}
            >
              <CardHeader className="border-bottom">
                <h6 style={{ fontFamily: "Poppins" }} className="m-0">
                  Agent Monitoring Setup
                </h6>
              </CardHeader>
              <Col md="auto" style={{ height: "inherit", overflowY: "auto" }}>
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style={{ marginLeft: "2%", fontFamily: "Poppins" }}
                >
                  <AuxCardHeader />
                  {/* creates the AUX reasons UI as well as fills the AUX reasons with the data */}
                  {this.createAuxs().length === 0
                    ? this.state.errorMessage !== ""
                      ? null
                      : progress
                    : null}
                  {this.isEmpty(this.state.auxCheckBox) &&
                  this.props.location.state !== null
                    ? this.props.location.state.type !== "post"
                      ? this.filltheAuxs()
                      : this.createAuxs()
                    : this.createAuxs()}
                </table>
                {this.createAuxs().length === 0 ? null : (
                  <HighLightWhenComponent
                    acdAlert={this.state.acdAlert}
                    acdWarnig={this.state.acdWarnig}
                    setStateacdAlert={(event) => {
                      this.setState({ acdAlert: event });
                    }}
                    setStateacdWarning={(event) => {
                      this.setState({ acdWarnig: event });
                    }}
                  />
                )}
                {this.createAuxs().length === 0 ? null : (
                  <DisplayOptionsCheckBox
                    loggedout={this.state.loggedout}
                    abandon={this.state.abandon}
                    setStateLoggedout={(event) => {
                      this.setState({ loggedout: event });
                    }}
                    setStateAbandon={(event) => {
                      this.setState({ abandon: event });
                    }}
                  />
                )}
              </Col>
            </Card>
          </Col>
          {this.state.loader ? (
            <ThemeProvider theme={theme}>
              <CircularProgress
                style={{
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 400,
                }}
                size={100}
              />
            </ThemeProvider>
          ) : null}
        </Row>
      </Container>
    );
  }
}
const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiCircularProgress: {
      // Name of the rule
      colorPrimary: {
        // Some CSS
        color: "#a6a6a6",
        opacity: 0.5,
      },
    },
  },
});
export default withOktaAuth(DisplayBoardSettings);
