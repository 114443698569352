import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

const DefaultLayoutPopUp = ({ children, noNavbar, noFooter }) => (
  <Container fluid>
      <Row>
      {children}
      </Row>
  </Container>
  // <div style={{paddingRight:10}}>
  //   {children}
  // </div>
);

DefaultLayoutPopUp.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayoutPopUp.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayoutPopUp;
