import React, { Component } from "react";
import { Row, Col, CardHeader, Button } from "shards-react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
class DisplayBoardsCardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }
  handleMenuClose() {
    this.setState({ anchorEl: null });
  }
  handleMenuOpen(e) {
    this.setState({ anchorEl: e.currentTarget });
  }
  render() {
    return (
      <CardHeader className="border-bottom">
        <Row>
          <Col>
            {/* {this.props.renderRedirect()} */}
            <h5 className="m-0">{this.props.headertitle}</h5>
          </Col>
          <Col>
            <a>
              <Button
                onClick={this.handleMenuOpen}
                style={{
                  float: "right",
                  backgroundColor: "#4DAA50",
                  fontFamily: "Poppins",
                  fontSize: 14,
                  borderRadius: 100,
                  borderColor: "white"
                }}
                active
              >
                Create new
              </Button>
              <Menu
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                keepMounted
                onClose={this.handleMenuClose}
                style={{
                  width: "inherit",
                  fontFamily: "Poppins",
                  fontSize: 14
                }}
              >
                <MenuItem
                style={{
                 
                  fontFamily: "Poppins",
                  fontSize: 14,
                 
                }}
                  onClick={() => {
                    this.props.gotoBoardHandler();
                  }}
                >
                  Display Board
                </MenuItem>
                <MenuItem
                 style={{
                 
                  fontFamily: "Poppins",
                  fontSize: 14,
                
                }}
                  onClick={() => {
                    this.props.gotoPodHandler();
                  }}
                >
                  Multi-Clinic Board
                </MenuItem>
              </Menu>
            </a>
          </Col>
        </Row>
      </CardHeader>
    );
  }
}

export default DisplayBoardsCardHeader;
