import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import "babel-polyfill";

ReactDOM.render(
            <App />, 
    document.getElementById('root'),
    );
if (module.hot) module.hot.accept();
serviceWorker.unregister();
