import React, { Component } from "react";

class MultiClinicBoardsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <tr>
        <td>{this.props.BoardName}</td>
        <td>{this.props.CreatedByUsername}</td>
        <td>
          <a
            href="javascript:void(0)"
            data-board-id="21"
            className="view-board"
            onClick={() => {
              this.props.onDisplayBoard(this.props.RealTimeBoardId);
            }}
          >
            <img
              src={require("../../assets/images/presentation_chart.png")}
              alt="View Board"
              title="View Board"
              border="0"
              width="16"
              height="16"
            />
          </a>
          &nbsp;
          <a
            href="javascript:void(0)"
            onClick={() =>
              this.props.onSettings(
                this.props.BoardName,
                this.props.RealTimeBoardId,
                "put",
                "pod"
              )
            }
          >
            <img
              src={require("../../assets/images/edit.png")}
              alt="Edit"
              title="Edit"
              border="0"
              width="16"
              height="16"
            />
          </a>
          &nbsp;
          <a
            href="javascript:void(0)"
            onClick={() => {
              this.props.onDelete(
                true,
                this.props.RealTimeBoardId,
                this.props.BoardName
              );
            }}
            className="board-delete"
            data-board-id="21"
          >
            <img
              src={require("../../assets/images/delete.png")}
              alt="Delete"
              width="16"
              height="16"
              title="Delete"
              border="0"
            />
          </a>
          &nbsp;
        </td>
      </tr>
    );
  }
}

export default MultiClinicBoardsRow;
