import React,{useState,useEffect,useRef} from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "shards-react";
import DisplayBoardSettingsBoardName from '../components/DisplayBoardsComponent/DisplayBoardSettingsBoardName';
import {withOktaAuth} from '@okta/okta-react';
import CallApis from '../DataServices/CallApis';
import CircularProgress from '@material-ui/core/CircularProgress';
import PodSettingSplit from '../components/DisplayBoardsComponent/PodSettingSplit';
import ClinicDropDown from '../components/DisplayBoardsComponent/ClinicDropDown';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PodCreateHeader from '../components/DisplayBoardsComponent/PodCreateHeader';

function CreatePod(props) {
    const [podName, setPodName] = useState(props.podname || "POD-");
    const [clinics,setClinics] = useState([]);
    const [splits,setSplits] = useState([]);
    const [selectedClinics,setSelectedClinics] = useState([]);
    const [displayedSplits,setDisplayedSplits] = useState([]);
    const [splitInfoStore,setSplitInfoStore] = useState({});
    const [user,setUser] = useState("");
    const [responseMessages,setResponseMesssages] = useState({status:'',message:''});
    const [appendClinicNames, setappendClinicNames] = useState(false)
    const [loader, setloader] = useState(false)
    const suffix = useRef("");
    useEffect(() => {  
      const prepareData = async () => {
        let clinicList=props.clinicData, splitList=props.splitData;
  
        // sorting the clinic names in dictionary order
        clinicList[1].sort((clinic1, clinic2) =>
          clinic1.label.localeCompare(clinic2.label)
        );
        setClinics(clinicList);
        setSplits(splitList);
      };
        const getLoggedUser = async ()=>{
          let user = await props.authService.getUser();
          setUser(user);
        }
        getLoggedUser();
        prepareData();
    }, []);

    useEffect(() => {
      if(props.podname && props.selectedclinics && props.selectedsplits)
        {
          setPodName(props.podname);
          let clinicstoedit = props.clinicData[1].filter((element) =>
            props.selectedclinics.includes(element.value)
          );
          setSelectedClinics(clinicstoedit);
          createSplits(clinicstoedit,props.splitData,props.selectedsplits);
      }
    }, [props.podname, props.selectedclinics, props.selectedsplits]);

    const handleChange = (event)=>{
      setSelectedClinics(event || []);
      createSplits(event || [])
    }

    const makePostRequest = async (postObject)=>{
      const accessToken =  props.authState.accessToken;
      CallApis.prototype.postNewPod(accessToken,postObject).then(response=>{
        let message_complete="";
          if(appendClinicNames){
            message_complete = "Pod created successfully\nsaved Pod name:- "+podName;
          }
          else
            message_complete= "Pod created successfully";
          setResponseMesssages({status:'PASS',message:message_complete})
          setloader(false);
          props.recall();
      }).catch(error=>{
        setloader(false);
        if(podName.length>100)
        {
          setResponseMesssages({status:'FAIL',message:"Pod name has excess number of characters"});
        }
        else if(podName.length==0)
        {
          setResponseMesssages({status:'FAIL',message:"Board name empty"});
        }
    
        else
        {
          setResponseMesssages({status:'FAIL',message:"Something went wrong"});
        }
        console.log(error)
      })
    }
    const makePutRequest = (putObject)=>{
      let token = props.authState.accessToken;
      let payload = {...putObject,createdBy:props.metadata.createdBy};
      CallApis.prototype.updatePod(token, props.podKey, payload)
      .then(resp=>{
        if(resp.status===200){
          setResponseMesssages({message:"POD edited successfully", status:"PASS"});
          setloader(false);
          props.recall();
        }
      })
      .catch(err=>{
        if(err.response){
          setResponseMesssages({status:"FAIL",message:err.response.data.Message})
          setloader(false);
        }
      });
    }
    const handleAppendClinics = (e) => {
      setappendClinicNames(e.target.checked)
      if (clinics.length > 0 && e.target.checked) {
        let clinic_names = selectedClinics.map((clobj) => clobj.label);
        suffix.current = clinic_names.slice(0, clinic_names.length - 1).join();
        suffix.current = clinic_names.length >1?suffix.current + " and " + clinic_names[clinic_names.length - 1]:clinic_names[0];
        let fullPodName = podName+ suffix.current;
        if(fullPodName.length > 60){
          setResponseMesssages({status:"FAIL",message:"Pod name longer than 60 characters"});
          setappendClinicNames(false);
          return
        }
        setPodName(fullPodName);
      }
     else{
       let suffixIndex = podName.match(suffix.current)?.index;
       let suffixStripped = podName?.substring(0,suffixIndex);
       setPodName(suffixStripped.trim());
     }
    };
    const handleSubmit = () => {
      setloader(true);
      let splits = [];
      for(const split in splitInfoStore){
        if(splitInfoStore[split].checkbox) splits.push( parseInt(split))
      }
      if(selectedClinics.length===0 || splits.length===0)
      {
        setResponseMesssages({status:'FAIL',message:"Cannot save empty Pod. Must contain at least 1 split"});
        setloader(false)
        return;
      }
      let clinics = selectedClinics.map(clinicObj=> clinicObj.value);
      let payload = {
        "podName":podName,
        "createdBy":user.name,
        "splits":splits,
        "clinics":clinics
      }
      if(props.type==="put"){
        makePutRequest(payload);
      }
      else if(props.type==="post"){
        makePostRequest(payload);
      }
      
    };
    const handleSplitChange = (stateInfo)=>{
        let [status,key,entity] = stateInfo;
        let tempInfoStore = {...splitInfoStore};
        tempInfoStore[key][entity] = status;
        setSplitInfoStore(tempInfoStore)
    }

    const createSplits = (clinics,altsplits,splitInfoFromState) => {
      let splitInfo ={};
      let splitsToUse= splits.length===0?altsplits:splits;
      splitsToUse = splitsToUse.filter((split) =>
        clinics.map((element) => element.value).includes(split.clinicKey)
      );
      for (let split of splitsToUse) {
        if(splitInfoStore.hasOwnProperty(split.splitKey)){
          splitInfo[split.splitKey] = splitInfoStore[split.splitKey]
        }else{
            splitInfo[split.splitKey] = {
              checkbox: false,
            };}
      }
      if(splitInfoFromState!==undefined && splitInfoFromState.length !== 0)
      {
        splitInfoFromState.forEach(key=>{
          if(splitInfo[key]!=undefined){
            splitInfo[key].checkbox=true
          }
          else{

            console.log('error key',splitInfo,key,splitInfo[key])
         

          }
        
         

        })
      }
      setSplitInfoStore(splitInfo);
      setDisplayedSplits(splitsToUse);
    };
    const showSplits = ()=>{
      let splitsJSX = [];
      for( let split of displayedSplits)
      {
          splitsJSX.push(
            <PodSettingSplit
              splitcheckbox={splitInfoStore[split.splitKey].checkbox}
              splitName={split.splitName}
              handleSplitChange={(...args)=>handleSplitChange(args)}
              splitKey={split.splitKey}
            />
          ); 
      }
      return splitsJSX;
    }
    const handlePodNameChange = newName=>{
      if(newName.length > 100)
      {
        console.log("pod name rule violation");
        return;
      }
      setPodName(newName);
    }
    const extraOption = <FormControlLabel disabled={selectedClinics.length===0}
    control={<Checkbox checked={appendClinicNames} onChange={handleAppendClinics} name="append-clinics" color="primary"/>}
    label= {<span style={{fontFamily:'Poppins'}}>Append Clinic Names</span>}
  />
    return (
      <Container fluid className="main-content-container px-4">
        {responseMessages.message !=="" ?<Card small className="md-3" style={{ margin: 20, textAlign: "center" }}>
          <CardBody className=" d-flex justify-content-between align-items-center">
            <span style={{ fontWeight: "bolder",fontFamily:'Poppins', textAlign: 'center',width:'100%',color: responseMessages.status!=='FAIL'?'#007acc':'#ea1d36' }}>{responseMessages.message}
            </span>
            <IconButton style={{float:'right'}} onClick={()=>setResponseMesssages({status:'',message:''})} size='small'>
                <HighlightOffIcon/>
            </IconButton>
          </CardBody>
        </Card> : null}

          <PodCreateHeader
          title="Create Pod"
          saveBtnTitle="Save Pod"
          disabled={ podName==="" || loader}
          postData={handleSubmit}
          putData={handleSubmit}
          location={props.location}
          goOnCancel={props.type==="put"?props.oncancel:"/MultiClinicBoardLists"}
          showTitle={!props.hideHeader}
          type={props.podKey?"put":"post"}
        />
        
        <DisplayBoardSettingsBoardName
          podName={podName}
          displayName="Pod Name"
          placeholder="pod name..."
          setState={handlePodNameChange}
          extraoptions={!props.hideHeader?extraOption:null}
          expandName={props.expandName}
        />
        {loader?<CircularProgress style={{ marginTop:'25%',marginLeft: "50%",position:'absolute',zIndex:'500'}} size={60}/>:null
        }
        {clinics.length !== 0 ? (
          <Row>
            <Col lg="12" xs="12" md="12" className="mb-4">
              <Paper style={{
                  minHeight:'70vh',
                  maxHeight:"75vh",
                  overflowY:'auto',
                  overflowX:'hidden'
              }}>
                  <ClinicDropDown acceptsMultiple={true}
                    populateDropDown={clinics[1]}
                    _handleChange={handleChange}
                    selectedOption={selectedClinics}
                    type="post"
                  /> 
                <Grid container justify='flex-start' spacing={1}>
                    {showSplits()}
                </Grid>
              </Paper>
            </Col>
          </Row>
        ) : (
          <CircularProgress style={{ marginLeft: "50%", marginTop: "25%" }} />
        )}
      </Container>
    );
}


export default withOktaAuth(CreatePod);