export default function() {
  return [

    {
      title: "Display Boards",
      htmlBefore: '<i class="material-icons">assessment</i>',
      icon:'assessments',
      to: "/DisplayBoards",
    },
    
    {
      title: "Display Boards Settings",
      icon:'settings',
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/DisplayBoardSettings",
    },
    //not required for current settings
    {
      title: "Pod Settings",
      icon:'settings',
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/PodSettings",
    },
    // {
    //   title: "Pod Settings",
    //   icon:'settings',
    //   htmlBefore: '<i class="material-icons">settings</i>',
    //   to: "/CreatePod",
    // },
    {
      title: "Multi-Clinic Boards",
      htmlBefore: '<i class="material-icons">assessment</i>',
      icon:'assessments',
      to: "/MultiClinicBoardLists",
    },
    
    {
      title: "Multi-Clinic Board settings",
      icon:'settings',
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/MultiClinicBoardSettings",
    }
  ];
}
