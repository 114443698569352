// method to check user authentication
async function checkAuthentication() {
  const authenticated = await this.props.authState.isAuthenticated;
  const sessionExists = await this.props.authService._oktaAuth.session.exists();
  if (!sessionExists) {
    //this.props.authService.logout("/");
  } else {
    console.log("session exists");
  }
  if (authenticated !== this.state.authenticated) {
    if (authenticated && !this.state.userInfo) {
      const userInfo = await this.props.authService.getUser();
      if (userInfo === undefined) {
        this.props.authService.login("/");
      }
      this.setState({ authenticated, userInfo });
    } else {
      this.setState({ authenticated });
    }
  }
}

export { checkAuthentication };
