import React, { Component } from 'react';

class AuxCardHeader extends Component {
  render() {
    return (
      <>
        <tr>
          <td colspan="9" style={{ padding: '3%' }}><b style={{ fontStyle: 'bold', fontWeight: 'bold',fontFamily:'Poppins' }}>Highlight when in the following aux reason for (n) seconds, (leave empty to highlight anytime):</b></td>
        </tr>
        <tr >
          <td colspan="6" style={{ paddingBottom: '3%' }}><b style={{ fontStyle: 'bold', fontWeight: 'bold' ,fontFamily:'Poppins',}}>Aux Reason</b></td>
          <td colspan="2" align="right" style={{ paddingBottom: '3%' }}><b style={{ marginRight: '2%', fontStyle: 'bold', fontFamily:'Poppins',fontWeight: 'bold' }}>Warning</b></td>
          <td colspan="2" align="right" style={{ paddingBottom: '3%' }}><b style={{ marginRight: '5%', fontStyle: 'bold',fontFamily:'Poppins', fontWeight: 'bold' }}>Alert</b></td>
        </tr>
      </>
    );
  }
}

export default AuxCardHeader;
