import React, { Component } from 'react';
import {
  Row,
  Col,
  CardHeader,
} from "shards-react";
import Select from 'react-select';

class ClinicDropDown extends Component {
  render() {
    return (
      <>
          <Row className="align-content-center p-3">
            <Col lg="2" className="d-flex align-items-center">
              <label htmlFor="feInputState" style={{ fontStyle: 'bold', fontWeight: 'bold',margin:0}}>{this.props.label}</label>
            </Col>
            <Col lg="8" >
              <Select
                value={this.props.selectedOption}
                isMulti={this.props.acceptsMultiple}
                onChange={(obj) => { this.props._handleChange(obj) }}
                options={this.props.populateDropDown === '' ? null : this.props.populateDropDown}
                isDisabled={this.props.populateDropDown !== '' && this.props.type !== 'put' ? false : true}
              />
            </Col>
          </Row>
        
      </>
    );
  }
}

export default ClinicDropDown;
