import React from "react";
// import DisplayBoardSplitTiles from './DisplayBoardSplitTiles';
import SplitTileComponent from "./SplitTileComponent";
class DisplayBoardTiles {
  createTiles(
    noofsplits,
    data,
    RealtimeBoardSettingsIndivisual,
    time,
    status,
    message,
    errorMessage,
    timeout,
    clinicDictionary
  ) {
    // noofsplits=0
    if (noofsplits > 0) {
      return (
        // <table border="0" cellspacing="1" cellpadding="5" width="100%" height="600">
        //     <DisplayBoardSplitTiles status={status} message={message} noofsplits={noofsplits} RealtimeBoardSettingsIndivisual={RealtimeBoardSettingsIndivisual} data={data} time={time} errorMessage={errorMessage} timeout={timeout}/>
        // </table>
        <SplitTileComponent
          noofsplits={noofsplits}
          RealtimeBoardSettingsIndivisual={RealtimeBoardSettingsIndivisual}
          data={data}
          time={time}
        />
      );
    }
    // else {
    //     return (<div style={{ textAlign: "center" }}><h3 style={{ fontWeight: "bolder" }}>NOTHING TO DISPLAY</h3></div>)
    // }
  }
}
export default DisplayBoardTiles;
