import React, { Component } from 'react';
import {
   Row,
   Col,
} from "shards-react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class DisplayOptionsCheckBox extends Component {
   render() {
      return (
         <Row>
            <Col className="mb-4" >
               <label for="700" style={{ fontWeight: 'bold', marginLeft: '2%' ,fontFamily:'Poppins'}} >Display Options:</label><br></br>
               <Row style={{ marginLeft: '6%' ,fontFamily:'Poppins'}}>
                  <FormControlLabel
                  style={{fontFamily:'Poppins'}}
                     control={
                        <Checkbox
                           checked={this.props.loggedout}
                           value="checkedB"
                           style={{fontFamily:'Poppins'}}
                           color="primary"
                           onChange={(event) => {
                              this.props.setStateLoggedout(event.target.checked)
                           }
                           }
                        />
                     }
                     label={<span style={{fontFamily:'Poppins'}}>Show logged out Agents</span>}
                  />
               </Row>
               <Row style={{ marginLeft: '6%', fontFamily:'Poppins'}}>
                  <FormControlLabel
                  style={{fontFamily:'Poppins'}}
                     control={
                        <Checkbox
                           checked={this.props.abandon}
                           value="checkedB"
                           color="primary"
                           style={{fontFamily:'Poppins'}}
                           onChange={(event) => {
                              this.props.setStateAbandon(event.target.checked)
                           }
                           }
                        />
                     }
                     label={<span 
                        style={{fontFamily:'Poppins'}}>Show Service Level Abandons</span>}
                  />
               </Row>
            </Col>
         </Row>
      );
   }
}

export default DisplayOptionsCheckBox;
