import React from "react";
import { Row, Col, Button } from "shards-react";
import PageTitle from "../DisplayBoardsComponent/PageTitle";
import PropTypes from "prop-types";
export default function PodCreateHeader(props) {
  return (
    <Row noGutters className="page-header py-4">
      {props.showTitle && (
        <PageTitle title={props.title} className="text-sm-left mb-3" />
      )}
      <Col style={{ float: "right", alignItems: "center" }}>
        <span
          style={{
            float: "right",
            fontFamily: "Poppins",
            justifyContent: "space-between"
          }}
        >
          <Button
            disabled={props.disabled}
            style={{
              fontFamily: "Poppins",
              backgroundColor: "#4DAA50",
              borderRadius: 100,
              borderColor: "white"
            }}
            onClick={() => {
              if (props.type !== undefined) {
                if (props.type === "put") {
                  props.putData();
                } else {
                  props.postData();
                }
              } else {
                props.postData();
              }
            }}
          >
            {props.saveBtnTitle}
          </Button>
          &nbsp;&nbsp;
          {!props.disabled && (
            <Button
              style={{
                fontFamily: "Poppins",
                backgroundColor: "#ea1d36",
                borderRadius: 100,
                borderColor: "white"
              }}
              href={props.goOnCancel}
              onClick={props.goOnCancel ? props.goOnCancel : null}
              active
            >
              Cancel
            </Button>
          )}
        </span>
      </Col>
    </Row>
  );
}

PodCreateHeader.propTypes = {
  postData: PropTypes.func.isRequired,
  putData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired
};
