import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import {withOktaAuth} from '@okta/okta-react'

 class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      name:''
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    //this.getUserName=this.getUserName.bind(this)
  }


  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  

  async getUserName(){
    const userinfo=await this.props.authService.getUser();
    this.setState({name:userinfo.name})
  }

  render() {
    if(this.state.name=='')
    {
      this.getUserName()
    }
    
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          {/* <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/0.jpg")}
            alt="User Avatar"
          />{" "} */}
          {/* {this.state.name==''?this.getUserName():null} */}
  <span className="d-none d-md-inline-block" style={{color:'white',marginTop:'15%'}}>{this.state.name}</span>
        </DropdownToggle>
        {/* <Collapse tag={DropdownMenu} left small open={this.state.visible}>
          <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons" >&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse> */}
      </NavItem>
    );
  }
}

export default withOktaAuth(UserActions) 
