import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout ,DefaultLayoutPopUp} from "./layouts";

// Route Views
import DisplayBoardSettings from "./views/DisplayBoardSettings";
import PodManager from "./views/PodSettingsManager";
import DisplayBoards from "./views/DisplayBoards";
import DisplayBoard from './views/DisplayBoard'
import MultiClinicBoards from './views/MultiClinicBoards';
import MultiClinicBoard from './views/MultiClinicBoard';
import MultiClinicBoardSettings from './views/MultiClinicBoardSettings';

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/DisplayBoards" />
  },
  {
    path: "/DisplayBoardSettings",
    layout: DefaultLayout,
    component:DisplayBoardSettings
  },
  {
    path: "/DisplayBoards",
    layout: DefaultLayout,
    component: DisplayBoards
  },
  {
    path: "/displayboard/",
    layout: DefaultLayoutPopUp, 
    component: DisplayBoard
  },
  {
    path: "/PodSettings",
    layout: DefaultLayout, 
    component: PodManager
  },
  // {
  //   path: "/CreatePod",
  //   layout: DefaultLayout, 
  //   component: CreatePod
  // },
  {
    path:'/MultiClinicBoardLists',
    layout:DefaultLayout,
    component: MultiClinicBoards
  },
  {
    path: "/realTimePodContainer/",
    layout: DefaultLayoutPopUp, 
    component: MultiClinicBoard
  },
  {
    path: "/MultiClinicBoardSettings",
    layout: DefaultLayout, 
    component: MultiClinicBoardSettings
  }
];
