import React, { Component } from "react";
import { Row, Col } from "shards-react";
import { CircularProgress, Grid, Card, CardHeader } from "@material-ui/core";

export class SplitTileComponent extends Component {
  colorLogic(
    splitKey,
    callsWaiting,
    oldestCallWaiting,
    RealtimeBoardSettingsIndivisual
  ) {
    const { BoardSetting } = RealtimeBoardSettingsIndivisual[0];
    const { splits = [] } = BoardSetting;
    let RealtimeBoardSettingsIndivisualSplits = splits;

    for (let i = 0; i < RealtimeBoardSettingsIndivisualSplits.length; i++) {
      if (splitKey == RealtimeBoardSettingsIndivisualSplits[i].key) {
        if (!oldestCallWaiting) {
          if (
            parseInt(callsWaiting) >=
            parseInt(RealtimeBoardSettingsIndivisualSplits[i].callswaitingalert)
          ) {
            return this.props.noofsplits <= 4 ? "blink-red" : "blink-red-three";
          } else if (
            parseInt(callsWaiting) >=
            parseInt(
              RealtimeBoardSettingsIndivisualSplits[i].callswaitingwarning
            )
          ) {
            return this.props.noofsplits <= 4
              ? "split-monitor-box-warning"
              : "split-monitor-box-three-warning";
          }
        } else {
          const minutes = parseInt(callsWaiting.slice(0, 2));
          const seconds = parseInt(callsWaiting.slice(3, 5));
          callsWaiting = minutes * 60 + seconds;
          if (
            parseInt(callsWaiting) >=
            parseInt(
              RealtimeBoardSettingsIndivisualSplits[i].oldestcallwaitingalert
            )
          ) {
            return this.props.noofsplits <= 4 ? "blink-red" : "blink-red-three";
          } else if (
            parseInt(callsWaiting) >=
            parseInt(
              RealtimeBoardSettingsIndivisualSplits[i].oldestcallwaitingwarning
            )
          ) {
            return this.props.noofsplits <= 4
              ? "split-monitor-box-warning"
              : "split-monitor-box-three-warning";
          }
        }
      }
    }

    return this.props.noofsplits <= 4
      ? "split-monitor-box"
      : "split-monitor-box-three";
  }

  renderSplits(noofsplits) {
    let arr = [];
    const colors = [
      "#805791",
      "#4DAA50",
      "#0D9DDB",
      "#004877",
      "#805791",
      "#4DAA50",
      "#0D9DDB",
      "#004877",
    ];

    for (let i = 0; i < noofsplits; i++) {
      arr.push(
        <Grid
          item
          lg={noofsplits <= 4 ? 6 : 4}
          xl={noofsplits <= 4 ? 6 : 4}
          md={noofsplits <= 4 ? 6 : 4}
          sm={12}
          style={{ display: "flex", alignItems: "stretch", width: "100%" }}
        >
          {/* 6 */}
          <Card style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <CardHeader
              style={{
                backgroundColor: "#cccccc",
                display: "flex",
                flex: 1,
                alignSelf: "stretch",
              }}
              title={
                <Grid item md={9} justifyContent="flex-end">
                  <span
                    className="split-header leftText"
                    title={this.props.data[0][i]}
                    style={{fontFamily:'Poppins'}}
                  >
                    {this.props.data[0][i] != "" ? (
                      this.props.data[0][i]
                    ) : (
                      <CircularProgress />
                    )} 
                  </span>
                  <span>{" "}</span>
                  <span className="split-header rightText" style={{fontFamily:'Poppins'}}>
                    Agents: {this.props.data[5][i]}/{this.props.data[6][i]}, 
                    ASA: {this.props.data[7][i]}
                  </span>
                </Grid>
              }
            />
            <Row className="split-monitor-row">
              <Col style={{ padding: 0, margin: 0 }}>
                <td
                  className={this.colorLogic(
                    this.props.data[4][i],
                    this.props.data[1][i],
                    false,
                    this.props.RealtimeBoardSettingsIndivisual
                  )}
                  bgcolor={colors[i]}
                  style={{ borderRight: "#ffffff 1px solid",fontFamily:'Poppins', height: "20vh" }}
                >
                  <div style={{fontFamily:'Poppins',fontSize:20}} class="label">Calls In Queue</div>
                  <div  style={{fontFamily:'Poppins',fontSize:20}} class="value">
                    {this.props.data[1][i] == "" ? "0" : this.props.data[1][i]}
                  </div>
                </td>
              </Col>
              <Col style={{ padding: 0, margin: 0 }}>
                <td
                  className={this.colorLogic(
                    this.props.data[4][i],
                    this.props.data[2][i],
                    true,
                    this.props.RealtimeBoardSettingsIndivisual
                  )}
                  bgcolor={colors[i]}
                  style={{ borderRight: "#ffffff 1px solid" ,fontFamily:'Poppins',fontSize:17}}
                >
                  <div style={{fontFamily:'Poppins',fontSize:20}} class="label">Oldest Call Waiting</div>
                  <div style={{fontFamily:'Poppins',fontSize:20}} class="value">
                    {this.props.data[2][i] == "" ? "0" : this.props.data[2][i]}
                  </div>
                </td>
              </Col>
              <Col style={{ padding: 0, margin: 0 }}>
                <td
                  className={
                    this.props.noofsplits <= 4
                      ? "split-monitor-box"
                      : "split-monitor-box-three"
                  }
                  style={{
                    fontWeight: "bold",
                    color: "#ffffff",
                    // padding: "20px",
                    textAlign: "center",
                    verticalAlign: "middle",
                    backgroundColor: colors[i],
                  }}
                >
                  <div style={{fontFamily:'Poppins',fontSize:20}} class="label">Abandoned</div>
                  <div style={{fontFamily:'Poppins',fontSize:20}} class="value">
                    {this.props.data[3][i] == "" ? "0" : this.props.data[3][i]}
                  </div>
                </td>
              </Col>
            </Row>
          </Card>
        </Grid>
      );
    }

    return arr;
  }

  render() {
    const lg = "6";
    return (
      <Grid container spacing={2}>
        {this.renderSplits(this.props.noofsplits).slice(
          0,
          this.props.noofsplits <= 4 ? 4 : 6
        )}
        <Grid item md={6}>
          {this.props.time[0] != "" ? (
            <tr>
              <td colspan="7" align="left">
                Data refreshed every 5 seconds. Last update:{" "}
                {this.props.time[0].split("-")[1] +
                  "/" +
                  this.props.time[0].split("-")[2] +
                  "/" +
                  this.props.time[0].split("-")[0] +
                  " " +
                  ((parseInt(this.props.time[1].substring(0, 2)) > 12
                    ? parseInt(this.props.time[1].substring(0, 2)) - 12
                    : parseInt(this.props.time[1].substring(0, 2))) +
                    this.props.time[1].substring(2, 8) +
                    " " +
                    (parseInt(this.props.time[1].substring(0, 2)) >= 12
                      ? "PM"
                      : "AM"))}
              </td>
            </tr>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

export default SplitTileComponent;
