import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
} from "shards-react";
class HighLightWhenComponent extends Component {
  render() {
    return (
      <Row>
        <Col className="mb-4">
          <label for="700" style={{ fontWeight: "bold",fontFamily:'Poppins'}}>
            Highlight When
          </label>
          <br></br>
          <Row className="d-flex justify-content-start align-items-center">
            <label style={{ fontWeight: "bold",padding:'0.5em',marginLeft:'6%' ,fontFamily:'Poppins'}}>
              ACD time greater than :{" "}
            </label>
            <FormInput
              type="number"
              style={{
                backgroundColor: "#FFFD82",
                width: "10%",
                fontWeight: "bold",
                fontStyle: "bold",
                paddingRight: "0%",
                textAlign: "center",
                fontFamily:'Poppins'
              }}
              className="textbox"
              name="Position"
              value={this.props.acdWarnig}
              onChange={(event) => {
                this.props.setStateacdWarning(event.target.value);
              }}
            />
            <FormInput
              type="number"
              style={{
                backgroundColor: "#ea1d36",
                width: "10%",
                fontWeight: "bold",
                fontFamily:'Poppins',
                fontStyle: "bold",
                paddingRight: "0%",
                textAlign: "center",
              }}
              className="textbox"
              name="Position"
              value={this.props.acdAlert}
              onChange={(event) => {
                this.props.setStateacdAlert(event.target.value);
              }}
            />&nbsp;
            <label for="700" style={{ fontWeight: "normal",fontFamily:'Poppins' }}>
              (seconds)
            </label>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default HighLightWhenComponent;
